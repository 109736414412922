import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Box, Typography, Button, IconButton } from "@mui/material";

import UserProfilePicture from "components/UserComponents/UserProfilePicture";
import Timestamp from "components/Timestamp";
import CommentList from "./CommentList";
import CommentForm from "components/Inputs/CommentForm";
import PostStats from "./PostStats";
import { Helmet } from "react-helmet";
import SearchBar from "components/Inputs/Searchbar";
import WhiteLabelNav from "components/navbar/WhiteLabelNav";
// icons
import {
  ChatBubbleOutlineOutlined,
  FavoriteBorderOutlined,
  FavoriteOutlined,
  ShareOutlined,
  Report,
  Save,
  Favorite,
} from "@mui/icons-material";
import { Image, Link } from "@chakra-ui/react";

// todo
// * update post details modal with post stat details

// Fetch post by id
const fetchPostById = async (postId) => {
  try {
    const response = await fetch(`http://localhost:8000/posts/${postId}`);
    if (!response.ok) {
      throw new Error("Failed to fetch post");
    }
    const postData = await response.json();
    return postData;
  } catch (error) {
    console.error("Error fetching post:", error);
    throw error;
  }
};

const PostDetails = () => {
  const { postId } = useParams();
  const [post, setPost] = useState({});
  const [comments, setComments] = useState([]);
  const [userPicturePath, setUserPicturePath] = useState("");
  const loggedInUserId = useSelector((state) => state.user);
  const [isComments, setIsComments] = useState(false);
  const [showFullText, setShowFullText] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const isLiked = Boolean(post.likes && post.likes[loggedInUserId]);
  const likeCount = post.likes ? Object.keys(post.likes).length : 0;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const postData = await fetchPostById(postId);
        setPost(postData);
        console.log("POST DATA", postData);
        setComments(postData.comments);
        setUserPicturePath(postData.userPicturePath);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
    return () => {
      // CLEANUP HERE LATER
    };
  }, [postId]);

  const truncateText = (text, maxLength) => {
    if (!text) return "";
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  const handleToggleText = () => {
    setShowFullText(!showFullText);
  };

  const patchLike = async () => {
    try {
      const response = await fetch(
        `http://localhost:8000/posts/${postId}/like`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId: loggedInUserId }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to patch like");
      }
      const updatedPost = await response.json();
      setPost(updatedPost);
    } catch (error) {
      console.error("Error patching like:", error);
    }
  };

  const addComment = async (newComment) => {
    try {
      const response = await fetch(
        `http://localhost:8000/posts/${postId}/comments`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId: loggedInUserId, comment: newComment }),
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to add comment. Status: ${response.status}`);
      }

      const updatedPost = await response.json();
      // Update comments state with the comments from the updated post data
      setComments(updatedPost.comments);
      // Update the post state
      setPost(updatedPost);
    } catch (error) {
      console.error("Error adding comment:", error);
      // Handle the error as needed (e.g., display an error message to the user)
    }
  };

  return (
    <>
      <Helmet>
        ( <title>{`Inkly - ${post.title}`}</title> )
      </Helmet>
      <WhiteLabelNav/>
      <Box height="100vh" padding={["20px", "20px", "90px"]}>
        <Box
          display="flex"
          flexDirection={["row", "row"]}
          justifyContent="center"
          paddingTop="30px"
        >
          {/* LEFT CONTAINER */}
          {/* IMAGE CONTAINER */}
          <Box
            sx={{
              backgroundImage: `url(${post.picturePath})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              width: "100%",
              height: "600px",
              borderRadius: "13px",
              flex: "4",
            }}
          >
            <Box position="absolute" bottom="0" width="100%" bgcolor="orange">
              <PostStats post={post} postId={postId} />
            </Box>
          </Box>

          {/* RIGHT CONTAINER */}
          {/* POST DETAILS */}
          <Box
            height="90vh"
            color="white"
            bgcolor="white"
            borderRadius="13px"
            padding="10px"
            paddingTop="0"
            flex="3"
          >
            {/* User Widget */}
            <Box
              borderRadius="13px"
              display="flex"
              alignItems="center"
              padding="20px"
              paddingTop="0"
            >
              {/* PROFILE PICTURE */}
              <Box display="flex" flexDirection="column" borderRadius="13px">
                {/* First / Lastname */}
                <Box
                  display="flex"
                  color="black"
    
              
                  borderRadius="10px"
                >
                  <Typography variant="p" fontSize={["27px"]}>
                    {post.title}
                  </Typography>
                </Box>

                {/* LINK TO USER PROFILE */}
                <Link href={`/profile/${post.userId}`} marginTop={["25px"]}>
                  <Typography variant="p" color="black" marginTop="25px">
                    <span>{post.firstName}</span> {post.lastName}
                  </Typography>
                </Link>
                <Timestamp createdAt={post.createdAt} />
                <Typography
                  variant="p"
                  fontSize={["14px"]}
                  color="black"
                  marginTop="25px"
                >
                  {post.description}
                </Typography>
              </Box>
            </Box>

            <hr style={{ border: "solid lightblue 1px " }} />
            <Box>
              {/* COMMENTS SECTION */}
              <Box
                mt="0.5rem"
                sx={{
                  padding: "1rem",
                }}
              >
                <Typography variant="h6" color="black">
                  Comments
                </Typography>
                <CommentList
                  comments={comments}
                  userPicturePath={userPicturePath}
                  textColor="black"
                />
                <CommentForm onSubmit={addComment} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PostDetails;
