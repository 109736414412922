import React from 'react';
import {Box, Text} from '@chakra-ui/react';
import WhiteLabelNav from '../components/navbar/WhiteLabelNav';

const NoMatchPage = () => {
  return (
<>
<WhiteLabelNav/>
<Box display='flex' alignItems='center' justifyContent='center' flexDirection='column' marginTop='50px'>
<h1 style={{fontWeight: 'bold', marginBottom: '35px', fontSize:'25px' }}>OOPS!</h1>
<img src='/404.png' alt='inkly 404'/>
<Box marginTop='50px'>
    <a href='/' style={{fontWeight: 'bold', marginTop:'50px'}}>Go Home</a>
</Box>

</Box>
</>
  )
}

export default NoMatchPage;