import React from 'react';
import {Box, Flex, Image, Text, Button} from '@chakra-ui/react';
import {Link} from 'react-router-dom';
import Footer from 'components/General/Footer';

const RegisterSuccess = () => {
  return (
<>
<Box>
<Flex direction='column' textAlign='center' justifyContent='center' alignItems='center'>
<Image src='./SuccessRegister.jpg' alt='SIGN UP SUCCESS :DDDD'/>
<Text as='h1' color='black'>THANKS FOR REGISTERING</Text>
<Link to='/login'>Go to homepage</Link>
</Flex>
</Box>
<Box position='absolute' top='0' bottom='0'>
<Footer/>
</Box>
</>
  )
}

export default RegisterSuccess
