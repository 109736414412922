import React, { useState, useEffect } from 'react';
import { Typography, Button } from '@mui/material';
import { Box, Image, Flex } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { IoOpenOutline as OpenPost } from "react-icons/io5";








const ProfileImageGrid = ({ user, userId, token, isProfile }) => {
  const [posts, setPosts] = useState([]);
  const [enlargedImg, setEnlargedImg] = useState(null);
  const dispatch = useDispatch();

  const handleImageClick = (post) => {
    setEnlargedImg(post);
  };

  const handleCloseEnlargedImg = () => {
    setEnlargedImg(null);
   
  };

  const getUserPosts = async () => {
    const response = await fetch(`http://localhost:8000/posts/${userId}/posts`, {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    dispatch(setPosts({ posts: data }));
    console.log(data);
  };

  useEffect(() => {
    if (isProfile) {
      getUserPosts();
    } else {
      console.log('No Such Profile');
    }
  }, []);

  return (
    <Box padding={['15px']}>
      <Flex flexWrap="wrap" gap={4} mt={['20px']} justifyContent="center">
        {user.posts.map((post, index) => (
          <Box
            key={index}
            position="relative"
            width="200px"
            height="200px"
            overflow="hidden"
            borderRadius="20px"
            onClick={() => handleImageClick(post)}
            cursor="pointer"
            transform={enlargedImg === post ? 'scale(1.2)' : 'scale(1)'}
            transition="transform 0.3s ease-in-out"
          >
            <Image src={post.picturePath} alt={post.title} width="100%" height="100%" objectFit="cover" />
            <Box
              position="absolute"
              bottom="0"
              left="0"
              right="0"
              bg="rgba(0, 0, 0, 0.9)"
              color="white"
              padding="8px"
              opacity={enlargedImg === post ? 0 : 1}
              transition="opacity 0.3s ease-in-out"
            >
              <Typography variant="body2">{post.description}</Typography>
            </Box>
            {enlargedImg === post && (
              <Box
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                zIndex="1"
              >
                <Button variant="contained" sx={{backgroundColor: 'black'}} onClick={handleCloseEnlargedImg}>
                  <OpenPost color='white'/>
                </Button>
              </Box>
            )}
          </Box>
        ))}
      </Flex>
    </Box>
  );
};

export default ProfileImageGrid;
