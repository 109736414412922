import React, { useState } from "react";
import { Box, Link, Avatar } from "@chakra-ui/react";
import Logo from "components/General/Logo";
import Button from '../General/Button';
import SearchBar from './Searchbar';
import styles from './styles.module.scss';
import FeedBar from "./Feedbar";

const Navbar = ({ selectedFeedIndex, setSelectedFeedIndex, navLinks, feeds }) => {
  const [isSelected, setIsSelected] = useState(false);

  const navLinkClick = () => {
    setIsSelected(true);
  };

  return (
    <div className={styles.container}>
      {/* Left Section */}
      <div className={styles.logoContainer}>
        <Logo />
      </div>




{/* Nav Links */}
      {/* Center Section */}
      <div className={styles.navContainer}>
        <div className={styles.feedLabel}>
          <h1>{feeds[selectedFeedIndex].label}</h1>
        </div>

        <div className={styles.linkContainer}>
          {navLinks.slice(0, 4).map((link, index) => (
             <Link key={index} to={link.url} className={styles.link}>
              
             <div className={styles.linkContent}>
               {link.icon && <span className={styles.icon}>{link.icon}</span>}
               <span className={styles.label}>{link.label}</span>
             </div>
           </Link>
          ))}
        </div>

        <div className={styles.searchBar}>
          <SearchBar />
        </div>
      </div>

      {/* Right Section */}
      <div className={styles.rightContainer}>
        <div className={styles.publishButton}>
          <Button text='Publish' />
        </div>

      <div className={styles.avatarContainer}>

      </div>

      </div>
    </div>
  );
};

export default Navbar;
