import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputBase,
  InputLabel,
  Paper,
  useMediaQuery,
  Typography,
  FormLabel,
  Snackbar,
  Slide,
  FormControlLabel,
  Checkbox,
  Alert,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLogin, setShop } from "state";
import { useFormik } from "formik";
import * as yup from "yup";
import Navbar from "components/navbar/WhiteLabelNav";

const loginSchema = yup.object().shape({
  email: yup.string().email().required("A valid email is required"),
  password: yup.string().required("A valid password is also required"),
});

const fetchShopData = async (shopId) => {
  const shopResponse = await fetch(`http://localhost:8000/shops/${shopId}`);
  return shopResponse;
};

const Login = ({ switchToRegister }) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberMe: false,
    },
    validationSchema: loginSchema,
    onSubmit: async (values, onSubmitProps) => {
      try {
        const loggedInResponse = await fetch(
          `http://localhost:8000/auth/login`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(values),
          }
        );
        const loggedIn = await loggedInResponse.json();

        onSubmitProps.resetForm();

        if (loggedIn && loggedIn.user && loggedIn.user.shop) {
          const shopResponse = await fetchShopData(loggedIn.user.shop);
          const shop = await shopResponse.json();
          dispatch(
            setLogin({
              user: loggedIn.user,
              token: loggedIn.token,
            })
          );
          dispatch(setShop({ shop }));
          navigate("/home");
        } else {
          dispatch(
            setLogin({
              user: loggedIn.user,
              token: loggedIn.token,
            })
          );
          navigate("/home");
        }

        if (values.rememberMe) {
          localStorage.setItem("userEmail", values.email);
        }

        formik.setErrors({
          email: "",
          password: "",
        });
        setOpenSnackbar(false);
      } catch (error) {
        console.error(error);
        setSnackbarMessage("Invalid email or password.");
        setOpenSnackbar(true);
      }
    },
  });

  const labelAnimationStyles = {
    label: {
      transition: "transform 0.2s ease-in-out",
    },
    labelClicked: {
      transform: "scale(0.8)",
    },
  };

  useEffect(() => {
    const storedEmail = localStorage.getItem("userEmail");
    if (storedEmail) {
      formik.setFieldValue("email", storedEmail);
    }
  }, []);



  
  useEffect(() => {
    if (
      formik.submitCount > 0 &&
      (formik.errors.email || formik.errors.password)
    ) {
      setSnackbarMessage("Error: Invalid email or password.");
      setOpenSnackbar(true);
    }
  }, [formik.errors, formik.submitCount]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        backgroundColor="white"
      >
        <Paper
          elevation={3}
          sx={{
            // width: "400px",
            position: "relative",
            bottom: "5rem",
            p: 4,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="h1"  color='gray'>
          Welcome Back
          </Typography>
          <Typography variant="body1" mt={1}>
            Please Sign In To Your Account!
          </Typography>
          <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
            <Box marginTop="30px">
              <FormControl
                fullWidth
                margin="normal"
                error={Boolean(formik.errors.email)}
              >
                <FormLabel htmlFor="email" sx={{ marginBottom: "10px",  }}>
                  Email Address
                </FormLabel>
                <InputBase
                  id="email"
                  name="email"
                  type="text"
                  placeholder="example@example.com"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  sx={{
                    width: "100%",
                    backgroundColor: "transparent",
                    borderRadius: "8px",
                    border: "0.30px #282828 solid",
                    // color: "#262626",
                    padding: "10px",
                    "&:focus": {
                      borderColor: "purple",
                    },
                  }}
                />
                <FormHelperText>{formik.errors.email}</FormHelperText>
              </FormControl>
              <FormControl
                fullWidth
                margin="normal"
                error={Boolean(formik.errors.password)}
              >
                <FormLabel htmlFor="password" sx={{ marginBottom: "10px" }}>
                  Password
                </FormLabel>
                <InputBase
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={togglePasswordVisibility} edge="end">
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  sx={{
                    width: "100%",
                    backgroundColor: "transparent",
                    borderRadius: "8px",
                    border: "0.30px #282828 solid",
                    color: "#262626",
                    padding: "10px",
                    "&:focus": {
                      borderColor: "blue.400",
                    },
                  }}
                />
                <FormHelperText>{formik.errors.password}</FormHelperText>
              </FormControl>
            </Box>
            <Button
              type="submit"
              sx={{
                mt: 2,
                width: "100%",
                borderRadius: "10px",
                backgroundColor: "black",
                color: "white",
                padding: "15px",
                transition: "background-color 0.4s, color 0.4s",
                "&:hover": {
                  backgroundColor: "white",
                  color: "black",
                },
              }}
            >
              Login
            </Button>
            <FormHelperText
              style={{
                marginTop: "14px",
                marginLeft: "5px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <a
                href="/resetPassword"
                style={{ color: "black", fontSize: "12px" }}
              >
                Forgot Password?
              </a>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.rememberMe}
                    onChange={formik.handleChange}
                    name="rememberMe"
                  />
                }
                label="Remember Me"
              />
            </FormHelperText>
          </form>
          <Typography variant="body2" mt={2}>
            Don't have an account yet?{" "}
            <a href="/register" style={{ color: "#4DC88D" }}>
              Sign Up Here
            </a>
          </Typography>
        </Paper>
        {!isMobile && (
          <Box ml={8} position="relative" bottom="60px">
            <img
              src="./login-images/October2023.png"
              alt="Art by Jason Yeno"
              style={{ maxWidth: "100%", maxHeight: "100%", height: "700px" }}
            />
          </Box>
        )}
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          TransitionComponent={(props) => <Slide {...props} direction="down" />}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity="error"
            sx={{
              width: "100%",
              fontSize: "20px",
              backgroundColor: "black",
              color: "white",
            }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
};

export default Login;
