import React from 'react'
import styles from './styles.module.scss'

// Soocial Iccons
import { FaSquarePinterest } from "react-icons/fa6";
import { FaInstagramSquare } from "react-icons/fa";
import { FaTwitterSquare } from "react-icons/fa";
import { FaBehanceSquare } from "react-icons/fa";


const index = ({user}) => {
 const name = `${user.firstName} ${user.lastName}`
 
    return (
<>

<div className={styles.container}>
    
<div className={styles.imageContainer} style={{ backgroundImage: `url(${user.picturePath})` }}>
</div>


<div className={styles.infoContainer}>

<div className={styles.info}>
<h1>{name}</h1>
<h2>Tattoo Artist</h2>
</div>

<div className={styles.socials}>
    <FaSquarePinterest size={30} className={styles.icon} />
    <FaInstagramSquare size={30}  className={styles.icon}/>
    <FaTwitterSquare size={30}  className={styles.icon}/>
    <FaBehanceSquare size={30}  className={styles.icon}/>
</div>




</div>
</div>
</>      
  )
}

export default index
