
import React, { useState } from 'react';
import { Box, Typography, Button, TextField, IconButton } from '@mui/material';
import {Text} from '@chakra-ui/react';


import UserImage from 'components/UserComponents/UserImage';
import UserProfilePicture from 'components/UserComponents/UserProfilePicture';


const CommentList = ({ comments, userPicturePath, onReply, textColor }) => {
  const [replyText, setReplyText] = useState('');
  const [replyingTo, setReplyingTo] = useState(null); 

  const handleReply = (commentId) => {
    onReply(commentId, replyText);
    setReplyText('');
    setReplyingTo(null);
  };

  return (
    <Box mt="0.5rem" sx={{
      maxHeight: '400px',
      overflowY: 'auto',
    }}>
      {comments.map((comment, i) => (
        <Box key={i} display="flex" marginTop="30px" marginBottom="30px">
          <UserProfilePicture size='50px'
          userPicturePath={comment.userPicturePath}/>
          <Box>
            <Typography sx={{ color: textColor, fontWeight: 'bold' }}>
              @{comment.username}
            </Typography>
            <Typography sx={{ color: textColor }}>{comment.text}</Typography>




          {/* REPLYING TO */}
            {comment._id === replyingTo ? ( // Show the reply form for the selected comment
              <div style={{marginTop: '30px'}}>
                <Box style={{display:'flex', alignItems: 'center', justifyContent: 'center'}}>
                <TextField
                  label="Reply"
                  variant="outlined"
                  size="small"
                  style={{backgroundColor: 'transparent', color:'white', borderRadius: '20px'}}
                  InputProps={{ style: { color: 'white', border: '#3C3F40 1px solid', borderRadius: '20px' } }} 
                  value={replyText}
                  onChange={(e) => setReplyText(e.target.value)}
                />
                <Button
                 style={{backgroundColor: 'transparent', color:'white', borderRadius: '20px'}}
                  variant="contained"
        
                  size="small"
                  onClick={() => handleReply(comment._id)} // Pass commentId to handleReply
                >
                  Reply
                </Button>
                </Box>
              </div>
            ) : (
              <IconButton
                color="primary"
                size="small"
                onClick={() => setReplyingTo(comment._id)} // Set the replyingTo state to open the reply form
              >
                <Text as='p' color='gray' fontSize='10px'>Reply</Text>
              </IconButton>
            )}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default CommentList;
