import React from 'react';
import { Box, Button, TextField, Typography, Link } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';

const shopRegisterSchema = yup.object().shape({
  shopName: yup.string().required('Shop Name is required'),
  businessLicense: yup.string().required('Business License is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  password: yup.string().required('Password is required').min(7, 'Password must be at least 7 characters'),
  phoneNumber: yup.string().required('Phone Number is required'),
  location: yup.string().required('Location is required'),
  address: yup.string().required('Address is required'),
  city: yup.string().required('City is required'),
  state: yup.string(),
  zipCode: yup.string(),
  description: yup.string(),
  openingHours: yup.array().of(
    yup.object().shape({
      day: yup.string(),
      hours: yup.string(),
    })
  ),
  // Add validation for other fields as needed
});

const initialValuesShopRegister = {
  shopName: '',
  businessLicense: '',
  email: '',
  password: '',
  phoneNumber: '',
  location: '',
  address: '',
  city: '',
  state: '',
  zipCode: '',
  description: '',
  openingHours: [{ day: '', hours: '' }],
  // Add initial values for other fields
};

const ShopRegisterForm = () => {
  const handleFormSubmit = async (values, onSubmitProps) => {
    try {
      console.log('Shop Registration Values:', values);

      const openingHoursString = JSON.stringify(values.openingHours);
      const formData = new FormData();
      formData.append('shopName', values.shopName);
      formData.append('businessLicense', values.businessLicense);
      formData.append('email', values.email);
      formData.append('password', values.password);
      formData.append('phoneNumber', values.phoneNumber);
      formData.append('location', values.location);
      formData.append('address', values.address);
      formData.append('city', values.city);
      formData.append('state', values.state);
      formData.append('zipCode', values.zipCode);
      formData.append('description', values.description);
      formData.append('openingHours', openingHoursString);

      // Add form data for other fields

      const savedShopResponse = await fetch('http://localhost:8000/shops/newshop', {
        method: 'POST',
        body: formData,
      });

      const savedShop = await savedShopResponse.json();

      if (savedShop) {
        console.log('New shop registered:', savedShop);
        onSubmitProps.resetForm();
      }
    } catch (error) {
      console.error('Error registering shop:', error);
    }
  };

  return (
    <Formik
      initialValues={initialValuesShopRegister}
      validationSchema={shopRegisterSchema}
      onSubmit={handleFormSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box display="grid" gap="20px">
            {/* Shop Name */}
            <TextField
              label="Shop Name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.shopName}
              name="shopName"
              error={Boolean(touched.shopName) && Boolean(errors.shopName)}
              helperText={touched.shopName && errors.shopName}
            />

            {/* Business License */}
            <TextField
              label="Business License"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.businessLicense}
              name="businessLicense"
              error={Boolean(touched.businessLicense) && Boolean(errors.businessLicense)}
              helperText={touched.businessLicense && errors.businessLicense}
            />

            {/* Email */}
            <TextField
              label="Email"
              type="email"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email}
              name="email"
              error={Boolean(touched.email) && Boolean(errors.email)}
              helperText={touched.email && errors.email}
            />

            {/* Password */}
            <TextField
              label="Password"
              type="password"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.password}
              name="password"
              error={Boolean(touched.password) && Boolean(errors.password)}
              helperText={touched.password && errors.password}
            />

            {/* Phone Number */}
            <TextField
              label="Phone Number"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.phoneNumber}
              name="phoneNumber"
              error={Boolean(touched.phoneNumber) && Boolean(errors.phoneNumber)}
              helperText={touched.phoneNumber && errors.phoneNumber}
            />

            {/* Location */}
            <TextField
              label="Location"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.location}
              name="location"
              error={Boolean(touched.location) && Boolean(errors.location)}
              helperText={touched.location && errors.location}
            />

            {/* Address */}
            <TextField
              label="Address"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.address}
              name="address"
              error={Boolean(touched.address) && Boolean(errors.address)}
              helperText={touched.address && errors.address}
            />

            {/* City */}
            <TextField
              label="City"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.city}
              name="city"
              error={Boolean(touched.city) && Boolean(errors.city)}
              helperText={touched.city && errors.city}
            />

            {/* State */}
            <TextField
              label="State"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.state}
              name="state"
              error={Boolean(touched.state) && Boolean(errors.state)}
              helperText={touched.state && errors.state}
            />

            {/* Zip Code */}
            <TextField
              label="Zip Code"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.zipCode}
              name="zipCode"
              error={Boolean(touched.zipCode) && Boolean(errors.zipCode)}
              helperText={touched.zipCode && errors.zipCode}
            />

            {/* Description */}
            <TextField
              label="Description"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.description}
              name="description"
              error={Boolean(touched.description) && Boolean(errors.description)}
              helperText={touched.description && errors.description}
            />

            {/* Opening Hours - Mapping over openingHours array */}
            {values.openingHours.map((dayHours, index) => (
              <div key={index}>
                <TextField
                  label={`Day ${index + 1}`}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    const updatedHours = [...values.openingHours];
                    updatedHours[index] = { ...updatedHours[index], day: e.target.value };
                    setFieldValue('openingHours', updatedHours);
                  }}
                  value={dayHours.day}
                />
                <TextField
                  label={`Hours ${index + 1}`}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    const updatedHours = [...values.openingHours];
                    updatedHours[index] = { ...updatedHours[index], hours: e.target.value };
                    setFieldValue('openingHours', updatedHours);
                  }}
                  value={dayHours.hours}
                />
              </div>
            ))}
            
            {/* Add other form fields here */}
            
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
            >
              Register Shop
            </Button>

            <Typography>
              Already have a shop? Login <Link to="/shop/login">here</Link>.
            </Typography>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default ShopRegisterForm;
