import React, { useState } from "react";
import { Box, useMediaQuery, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import Navbar from "components/navbar";

import BottomNav from "components/BottomNav";
import FriendListWidget from "scenes/widgets/FriendListWidget";
import FeedBar from "scenes/feeds/FeedBar";
import UserHomeModals from "components/contentModals/UserDashboard/UserHomeModals";
import ShopPostModal from "components/contentModals/UserDashboard/ShopPostModal";
import Friend from "components/UserComponents/Friend";




// React icons
import { TiArrowShuffle } from "react-icons/ti";
import { GrCubes } from "react-icons/gr";
import { IoIosNotifications } from "react-icons/io";
import { CgCommunity } from "react-icons/cg";



// Feeds
import HomeFeed from "scenes/feeds/HomeFeed";
import PublicFeed from "scenes/feeds/PublicFeed";
import DiscoverFeed from "scenes/feeds/Discover";


const HomePage = () => {
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const { _id, picturePath, shop } = useSelector((state) => state.user);
  const user = useSelector((state) => state.user);

  const [selectedFeedIndex, setSelectedFeedIndex] = useState(0);
  const [previousFeedIndex, setPreviousFeedIndex] = useState(0);
  const [isEntering, setIsEntering] = useState(false);
  const [isLeaving, setIsLeaving] = useState(false);

  const navLinks = [
    {
      label: "Feed",
      url: "/explore",
      icon: <TiArrowShuffle/>
    },
    {
      label: "Explore",
      url: "/Community",
      icon: <CgCommunity/>
    },
    {
      label: "Notifications",
      url: "/gallery",
      icon: <IoIosNotifications/>
    },


  ];

  const feeds = [
    {
      label: "Home",
      component: <HomeFeed />,
    },
    {
      label: "Community",
      component: <PublicFeed />,
    },
    {
      label: 'Discover',
      component: <DiscoverFeed/>
    }
  ];

  return (
    <>
      <Navbar
        selectedFeedIndex={selectedFeedIndex}
        setSelectedFeedIndex={setSelectedFeedIndex}
        feeds={feeds}
        navLinks={navLinks}
      />
      <Box>{feeds[selectedFeedIndex].component}</Box>
      <BottomNav/>
    </>
  );
};

export default HomePage;
