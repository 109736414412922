import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import HomePage from "pages/temp/Standby/Homepage";
import LoginPage from "scenes/loginPage";
// OLD PROFILE PAGE


import { useMemo } from "react";
import { useSelector } from "react-redux";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "./theme";
import EditProfileScreen from "pages/profilePage/EditProfileScreen";
import CreatePost from "scenes/createPost/CreatePost";
import LoginForm from "scenes/loginPage/LoginForm";
import RegisterForm from "pages/register/RegisterForm";
import ArtistRegister from "scenes/register/artistRegister/index";
import AccountTypeSelect from "scenes/register/AccountTypeSelect";
import ArtistRegisterTest from "scenes/register/artistRegister/RegisterForm";
import { ChakraProvider, extendTheme, CSSReset } from "@chakra-ui/react";
import EditProfile from "pages/profilePage/EditProfile";

// ** Route Components
import HomeDashboard from './pages/homePage'



// ** User Components
import ProfilePage from "components/UserProfile/ProfilePage";



// ** Admin Pages
import UserGallery from 'components/UserGallery/index';







import NoMatchPage from "scenes/404";
import Loader from "components/Loader";
import ShopRegister from "scenes/register/shopRegister/Multistep/ShopRegisterForm";
import PostDetails from "components/postComponents/PostDetails";


function App() {
  const mode = useSelector((state) => state.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const isAuth = Boolean(useSelector((state) => state.token));

  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path="/" element={<HomeDashboard />}/>
            
            {/* POST ROUTES */}
            <Route path="/post/:postId" element={<PostDetails />} />


            {/* USER ROUTES */}
            <Route path="/register" element={<RegisterForm />} />


            
            {/*******LOGIN ROUTES *******/}
            <Route path="/login" element={<LoginForm />} />
            <Route
              path="/home"
              element={isAuth ? <HomeDashboard /> : <Navigate to="/login" />}
            />

          // ** Profile Routes
            <Route path="/profile/:userId" element={<ProfilePage />} />

            
            <Route path="/profile" element={<ProfilePage />} />


            
            <Route path="/new-post" element={<CreatePost />} />{" "}
            <Route
              path="/profile/edit/"
              element={isAuth ? <EditProfile /> : <Navigate to="/" />}
            />
            <Route element={<NoMatchPage />} path="*" />
            {/* SHOP ROUTES */}
            <Route path="/register/shopRegister" element={<ShopRegister />} />



          {/* ADMIN ROTUES */}
        <Route path='/everyone' element={<UserGallery/>}/>



          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
