import React from "react";
import { Box, Typography } from "@mui/material";
import Timestamp from "../Timestamp";


const UserImage = ({
  picturePath,
  size = "60px",
  activeStatus = true,
  name,
  location,
  createdAt,
  userPicturePath,
}) => {
  const IMAGE_FETCH_URL = `${picturePath}`;

  return (
    <Box display="flex" alignItems="center">
      <Box>
        <img
          alt={name}
          crossOrigin="anonymous"
          style={{
            objectFit: "cover",
            width: size,
            height: size,
            borderRadius: "50%",
            overflow: "hidden",
          }}
          src={IMAGE_FETCH_URL}
        />
      </Box>
      <Box ml={0.5}>
        <Typography variant="h6" color="white">
          {name}
        </Typography>
        <Typography variant="body2" color="gray">
          {location}
        </Typography>
      </Box>
    </Box>
  );
};

export default UserImage;
