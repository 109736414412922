import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Field, ErrorMessage } from "formik";
import { useDropzone, } from "react-dropzone";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Typography,
  useTheme,
  FormControlLabel,
  Checkbox
} from "@mui/material";

import {
  userUpdateRequest,
  userUpdateSuccess,
  userUpdateFail
} from "../../state/index";

import '../../Styles/ProfileScreen.css';
import UserImage from "components/UserComponents/UserImage";

import BottomNav from "components/BottomNav";
import "./index.css";
import UserWidget from "scenes/widgets/UserWidget";

const validationSchema = yup.object().shape({
  username: yup.string().required("required"),
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  email: yup.string().email("Invalid email").required("Required"),
  password: yup.string(),
  bio: yup.string(),
  bookingLink: yup.string(),
  employedAt: yup.string(),
  location: yup.string(),
  occupation: yup.string(),
  picturePath: yup.string()
});

const EditProfileScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const loggedInUserId = useSelector((state) => state.user._id);
  const token = useSelector((state) => state.token);
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const handleSubmit = async (values, { resetForm }) => {
    try {
      dispatch(userUpdateRequest()); // Start  loading state

      const response = await fetch(
        `http://localhost:8000/users/${user._id}/profile/edit`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify(values)
        }
      );

      if (response.ok) {
        const updatedUser = await response.json();
        dispatch(userUpdateSuccess(updatedUser)); // Update the user profile in Redux state
        console.log(values);
        // const user = updatedUser;
        resetForm();
        // window.location.reload(); // reload the page
      } else {
        dispatch(userUpdateFail("Profile update failed"));
      }
    } catch (error) {
      dispatch(userUpdateFail("Error updating profile: " + error.message));
    }
  };

  return (
    <div className="editProfile_wrapper">

      <div className="form_wrapper">
        <Formik
          initialValues={{
            username: user.username,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            password: user.password,
            bio: user.bio,
            bookingLink: user.bookingLink,
            employedAt: user.employedAt,
            location: user.location,
            occupation: user.occupation,
            picturePath: user.picturePath
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            resetForm,
            touched
          }) => (
            <form onSubmit={handleSubmit} className="form_container">
              <div className="formField">
                <TextField
                  label="User Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.username || ""}
                  name="username"
                  sx={{ gridRow: "span 4" }}
                  className="text_input"
                />
                <ErrorMessage name="username" component="div" />
              </div>
              <div className="formField">
                <TextField
                  label="First Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstName || ""}
                  name="firstName"
                  sx={{ gridRow: "span 4" }}
                  className="text_input"
                />
                <ErrorMessage name="firstName" component="div" />
              </div>

              <div className="formField">
                <TextField
                  label="Last Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lastName || ""}
                  name="lastName"
                  sx={{ gridColumn: "span 4" }}
                  className="text_input"
                />
                <ErrorMessage name="lastName" component="div" />
              </div>

              <div className="formField">
                <TextField
                  label="Email Adress"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email || ""}
                  name="email"
                  sx={{ gridColumn: "span 4" }}
                  className="text_input"
                />
                <ErrorMessage name="email" component="div" />
              </div>

              <div className="formField">
                <TextField
                  label="Bio"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.bio || ""}
                  name="bio"
                  sx={{ gridColumn: "span 4" }}
                  className="text_input"
                  variant="outlined"
                  multiline
                  rows={4}
                />
                <ErrorMessage name="bio" component="div" />
              </div>

              <div className="formField">
                <TextField
                  label="Location"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.location || ""}
                  name="location"
                  sx={{ gridColumn: "span 4" }}
                  className="text_input"
                />
                <ErrorMessage name="location" component="div" />
              </div>

              <div className="formField">
                <TextField
                  label="Occupation"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.occupation || ""}
                  name="occupation"
                  sx={{ gridColumn: "span 4" }}
                  className="text_input"
                />
                <ErrorMessage name="occupation" component="div" />
              </div>

              <div className="formField">
                <TextField
                  label="Employed At"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.employedAt || ""}
                  name="employedAt"
                  sx={{ gridColumn: "span 4" }}
                  className="text_input"
                />
                <ErrorMessage name="employedAt" component="div" />
              </div>

              {/* PASSWORD FIELD */}

              {/* 
              <div className="formField">
                
                <TextField
                  label="Password"
                  type="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password || ""}
                  name="password"
                  sx={{ gridColumn: "span 4" }}
                  className="text_input"
                />
                <ErrorMessage name="password" component="div" />
              </div> */}

              {/* Add other form fields as needed */}

              <Button
                type="submit"
                variant="contained"
                disabled={isSubmitting}
                sx={{
                  marginTop: "50px",
                  color: "white",
                  background: "#2C2E37"
                }}
              >
                Update profile
              </Button>
            </form>
          )}
        </Formik>
      </div>

    </div>
  );
};

export default EditProfileScreen;
