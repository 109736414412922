import React from "react";
import { Text, Box, Flex } from "@chakra-ui/react";
import UserProfilePicture from "components/UserComponents/UserProfilePicture";
import UserStatBar from "../UserComponents/UserStatBar";
import { Typography } from "@mui/material";
import UserProfileButtons from "./UserProfileButtons";

const UserProfileWidget = ({ user }) => {
  const fullName = `${user.firstName} ${user.lastName}`;

  return (
    <Box>
      <Flex
        direction="column"
        textAlign="center"
        justifyContent="center"
        alignItems="center"
        position="relative"
      >
        {/* Background image with overlay */}
        <Box
          position="relative"
          width="100%"
          height="200px"
          backgroundImage={`url(${user.bgPicturePath})`}
          backgroundSize="cover"
          backgroundPosition="center"
          borderRadius="20px"
        >
          {/* Dark opacity overlay */}
          <Box
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
            borderRadius="20px"
            backgroundColor="black"
            opacity="0.5" // Adjust opacity value as needed
          />

          {/* Profile picture */}
          <Box
            position="absolute"
            top="90%"
            left="50%"
            transform="translate(-50%, -50%)"
          >
            <UserProfilePicture
              userPicturePath={user.picturePath}
              alt={user.firstName}
              size="90px"
            />
          </Box>
        </Box>

        {/* USER INFORMATION */}
        <Box marginTop="30px">
          <Typography variant="h6">{fullName}</Typography>
          <Text as="p">@{user.username}</Text>
          <Text as="p">{user.accountType}</Text>
          <Typography variant="p">{user.bio}</Typography>
          <UserProfileButtons user={user} />
        </Box>
      </Flex>
    </Box>
  );
};

export default UserProfileWidget;
