import React from 'react';
import {Text, Box, Link} from '@chakra-ui/react';

const WhiteLabelNav = () => {
  return (
    <Box textAlign='left'  padding='2rem 2.5rem' fontSize={['25px']} background='white' fontWeight='bold'>
    <Link to='/home'>INKLY</Link>
    </Box>
  ) 
}

export default WhiteLabelNav
