import {
  ManageAccountsOutlined,
  EditOutlined,
  LocationOnOutlined,
  WorkOutlineOutlined,
} from "@mui/icons-material";
import { Box, Typography, Divider, useTheme } from "@mui/material";
import UserImage from "components/UserComponents/UserImage";
import FlexBetween from "components/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LogoutButton from "components/Buttons/LogoutButton";
import {Button} from '@chakra-ui/react'
import ImageGrid from "components/UserComponents/ImageGrid";
import FriendListWidget from "../FriendListWidget";
import ProfileCard from '../UserWidgets/ProfileCard/index';

import styles from './styles.module.scss'


const UserSideWidgetTwo = ({ userId, picturePath }) => {
  const [userPosts, setUserPosts] = useState([]);
  const [user, setUser] = useState(null);
  const { palette } = useTheme();
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  const dark = palette.neutral.dark;
  const medium = palette.neutral.medium;
  const main = palette.neutral.main;

  const getUser = async () => {
    const response = await fetch(`http://localhost:8000/users/${userId}`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    setUser(data);
  };

  useEffect(() => {
    getUser();
  }, []); 

  if(!user) {
    return null;
  }

  const {
    firstName,
    lastName,
    username,
    location,
    occupation,
    viewedProfile,
    bio,
    impressions,
    friends,
    featuredWork,
    bgPicturePath,
    employedAt
  } = user;
const name = `${firstName} ${lastName}`
  
  return (
<div className={styles.container}>
<ProfileCard user={user}/>
</div>
  );
};

export default UserSideWidgetTwo;
