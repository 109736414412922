import {
  EditOutlined,
  DeleteOutlined,
  AttachFileOutlined,
  GifBoxOutlined,
  ImageOutlined,
  MicOutlined,
  MoreHorizOutlined,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  Typography,
  InputBase,
  useTheme,
  IconButton,
  useMediaQuery,
  TextField
} from "@mui/material";
import {Button} from '@chakra-ui/react';
import FlexBetween from "components/FlexBetween";
import Dropzone from "react-dropzone";
import UserImage from "components/UserComponents/UserImage";
import WidgetWrapper from "components/WidgetWrapper";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPosts } from "state";



const ImagePlaceholder = () => (

  <Box
    sx={{
      height:300,
      border: "1px dashed gray",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Typography>Add Image Here</Typography>
  </Box>
);

const MyPostWidget = ({ picturePath, setShowCreatePostModal }) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [title, setTitle] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [isImage, setIsImage] = useState(false);
  const [image, setImage] = useState(null);
  const [post, setPost] = useState("");
  const { palette } = useTheme();
  const { _id } = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  const mediumMain = palette.neutral.mediumMain;
  const medium = palette.neutral.medium;

  const handleNextStep = () => {
    setStep(step + 1)
  };

  const handlePreviousStep = () => {
    setStep(step - 1)
  }

  const handlePost = async () => {
    const formData = new FormData();
    formData.append("userId", _id);
    formData.append('title', title)
    formData.append("description", post);
    if(imageUrl.startsWith('http://') || imageUrl.startsWith("https://")) {
      formData.append('picturePath', imageUrl);
    } else if (image) {
      formData.append('picture', image);
      formData.append('picturePath', image.name);
    }

    const response = await fetch( `http://localhost:8000/posts`, {
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      body: formData,
    });
    const posts = await response.json();
    dispatch(setPosts({ posts }));
    setImage(null);
    setPost("");
  };

  return (
    <WidgetWrapper>
      <Box maxHeight='700px' width={['265px']}>
        <Box gap="1.5rem" >
        {step === 1 && (
  <Dropzone
    acceptedFiles=".jpg,.jpeg,.png"
    multiple={false}
    onDrop={(acceptedFiles) => setImage(acceptedFiles[0])}
  >
    {({ getRootProps, getInputProps }) => (
      <Box {...getRootProps()} sx={{ "&:hover": { cursor: "pointer" } }}>
        <input {...getInputProps()} />
        {!image ? (
          <ImagePlaceholder />
        ) : (
          <Box
            sx={{
              width: '100%',
              height: '300px',
              overflow: 'hidden',
              position: 'relative',
            }}
          >
            <img
              src={URL.createObjectURL(image)}
              alt="....Ink"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          </Box>
        )}
      </Box>
    )}
  </Dropzone>
)}

          {step === 2 && (
            <Box>
              <TextField
                label="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                fullWidth
                margin="normal"
                variant="outlined"
              />
              <InputBase
                placeholder="What's on your mind..."
                onChange={(e) => setPost(e.target.value)}
                value={post}
                sx={{
                  width: "100%",
                  backgroundColor: "lightgray",
                  borderRadius: '10px',
                  padding: "1rem 2rem",
                  height: '200px'
                }}
              />
            </Box>
          )}

          <Divider sx={{ margin: "1.25rem 0" }} />

          {step === 1 && (
            <TextField
              label="Image URL"
              value={imageUrl}
              onChange={(e) => setImageUrl(e.target.value)}
              fullWidth
              variant="outlined"
              mt="1rem"
            />
          )}


          {step === 2 && (
            <>
              <Button  onClick={handlePreviousStep}>Previous</Button>
              <Button onClick={handlePost}>Post</Button>
            </>
          )}


{step === 1 ? (
            <Button onClick={handleNextStep}>Next</Button>
          ): null}


          {/* <Button
            onClick={handlePost}
            sx={{
              color: 'white',
              backgroundColor: 'black',
              borderRadius: "3rem",
            }}
          >
            POST
          </Button> */}
        </Box> 

        
      </Box>      
    </WidgetWrapper>
  );
};

export default MyPostWidget;
