import { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Formik, Field } from "formik";
import * as yup from "yup";

const registrationSchema = yup.object().shape({
  username: yup.string().required("Username is required"),
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  password: yup.string().required("Password is required"),
  // Add validation for other fields like location, occupation, etc.
});

const RegistrationForm = () => {
  const [isRegistered, setIsRegistered] = useState(false);
  const { palette } = useTheme();

  const handleRegistration = async (values) => {
    try {
      // Send a POST request to your backend registration endpoint
      const response = await fetch("http://localhost:8000/auth/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });

      if (response.ok) {
        setIsRegistered(true);
      } else {
        // Handle registration error, e.g., display an error message
        console.error("Registration failed");
      }
    } catch (error) {
      console.error("Registration error:", error);
    }
  };

  return (
    <Formik
      initialValues={{
        username: "",
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        // Add initial values for other fields
      }}
      validationSchema={registrationSchema}
      onSubmit={handleRegistration}
    >
      {({ handleSubmit, handleChange, values, errors, touched }) => (
        <form onSubmit={handleSubmit}>
          <Box>
            <Typography variant="h5">Registration</Typography>
            <Field
              as={TextField}
              fullWidth
              name="username"
              label="Username"
              value={values.username}
              onChange={handleChange}
              error={touched.username && Boolean(errors.username)}
              helperText={touched.username && errors.username}
            />
            <Field
              as={TextField}
              fullWidth
              name="firstName"
              label="First Name"
              value={values.firstName}
              onChange={handleChange}
              error={touched.firstName && Boolean(errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />
            <Field
              as={TextField}
              fullWidth
              name="lastName"
              label="Last Name"
              value={values.lastName}
              onChange={handleChange}
              error={touched.lastName && Boolean(errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
            <Field
              as={TextField}
              fullWidth
              name="email"
              label="Email"
              value={values.email}
              onChange={handleChange}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
            />
            <Field
              as={TextField}
              fullWidth
              type="password"
              name="password"
              label="Password"
              value={values.password}
              onChange={handleChange}
              error={touched.password && Boolean(errors.password)}
              helperText={touched.password && errors.password}
            />
            {/* Add fields for other registration data */}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
            >
              Register
            </Button>
            {isRegistered && (
              <Typography variant="body2" sx={{ color: palette.success.main }}>
                Registration successful!
              </Typography>
            )}
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default RegistrationForm;
