import React, { useEffect, useState } from 'react';
import {useSelector, useDispatch} from 'react-redux'
import { useParams } from 'react-router-dom';
import UserProfilePicture from '../UserComponents/UserProfilePicture';
import {Box, Text, Image, Button, Flex} from '@chakra-ui/react';
import WhiteLabelNav from 'components/navbar/WhiteLabelNav';
import ProfileImageGrid from './ProfileImageGrid';

import UserProfileWidget from './UserProfileWidget';



const ProfilePage = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);


  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <>
<Box>


<Flex direction='column' align='center' textAlign='center' justify='center' marginTop={['60px']}>     

    <UserProfileWidget user={user}/>
    <ProfileImageGrid user={user}/>

</Flex>
</Box>
</>
  );
};

export default ProfilePage;
