import React from 'react'
import MyPostWidget from 'scenes/widgets/CreatePost'
import BottomNav from '../../components/BottomNav';
const CreatePost = () => {
  return (
    <>
    <div>
      <MyPostWidget/>
      <BottomNav/>
    </div>
    </>
  )
}

export default CreatePost;
