// todo
// * create tooltips for the post details to let users know what the input will be used for
// * implement alert dialog to confirm post creation and moderation & deletion agreement.
// * register success displays regardless of server error fix



import React, { useState } from "react";
import { Text, Image } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import Dropzone from "react-dropzone";
import { setPosts } from "state";
import TattooStyleTagPicker from "components/General/TagPicker";

import {
  EditOutlined,
  DeleteOutlined,
  AttachFileOutlined,
  GifBoxOutlined,
  ImageOutlined,
  MicOutlined,
  Clear,
  MoreHorizOutlined,
} from "@mui/icons-material";
import { FaDollarSign as MoneyIcon } from "react-icons/fa";
import { FaClock as Clock } from "react-icons/fa";
import InputAdornment from "@mui/material/InputAdornment";
import {
  Divider,
  Typography,
  Box,
  Button,
  InputBase,
  useTheme,
  useMediaQuery,
  TextField,
  Modal,
  IconButton,
} from "@mui/material";

import Alert from "@mui/material/Alert";
import { Link } from "react-router-dom";

const PostWidgeTwo = () => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [title, setTitle] = useState("");
  const [ sessionTime, setSessionTime] = useState('');
  const [sessionPrice, setSessionPrice] = useState('')
  const [imageUrl, setImageUrl] = useState("");
  const [isImage, setIsImage] = useState(false);
  const [image, setImage] = useState(null);
  const [post, setPost] = useState({});
  const [selectedStyles, setSelectedStyles] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const { palette } = useTheme();
  const { _id } = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  const mediumMain = palette.neutral.mediumMain;
  const medium = palette.neutral.medium;

  const handleNextStep = () => {
    if (step === 1) {
      if (!image && !imageUrl.trim()) {
        alert("Please upload an image or enter an image URL");
        return;
      } else if (imageUrl.trim() && !isValidImageUrl(imageUrl)) {
        alert("Please enter a valid image URL");
        return;
      }
    } else if (step === 2 && !title.trim()) {
      alert("Please enter title");
      return;
    }
    setStep(step + 1);
  };

  const isValidImageUrl = (url) => {
    const imageUrlRegex = /\.(jpeg|jpg|gif|png|svg)$/i;
    return imageUrlRegex.test(url);
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const handlePost = async () => {
    const formData = new FormData();
    formData.append("userId", _id);
    formData.append("title", title);
    formData.append("description", post);
    formData.append("styles", JSON.stringify(selectedStyles)); 
    formData.append('sessionTime', sessionTime);
    formData.append('sessionPrice', sessionPrice)
    if (imageUrl.startsWith("http://") || imageUrl.startsWith("https://")) {
      formData.append("picturePath", imageUrl);
    } else if (image) {
      formData.append("picture", image);
      formData.append("picturePath", `/assets/${image.name}`);
    }

    const response = await fetch(`http://localhost:8000/posts`, {
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      body: formData,
    });
    const posts = await response.json();
    dispatch(setPosts({ posts }));
    setImage(null);
    setPost("");
    setShowSuccessModal(true);
  };

  const ImagePlaceholder = () => (
    <Box
      sx={{
        height: 300,
        border: "1px dashed gray",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
      }} 
    >
      <Image
        src="https://cdn.dribbble.com/users/605/screenshots/797031/media/87a9b371d0ba80d8a1c6595f4d4a214f.png?resize=800x600&vertical=center"
        alt="Placeholder"
        style={{ objectFit: "cover" }}
      />
    </Box>
  );

  return (
    <>
      <Box style={{ backgroundColor: "black" }} display="flex">
        <Box maxWidth={["1500px"]} style={{ backgroundColor: "white" }}>
          {/* STEP ONE IMAGE UPLOAD */}
          {step === 1 && (
            <Box textAlign="center">
              <Text as="h1" fontFamily="Passion One" fontSize={["26.2px"]}>
                Upload an image of the tattoo
              </Text>
              <Dropzone
                acceptedFiles=".jpg,.jpeg,.png"
                multiple="false"
                onDrop={(acceptedFiles) => setImage(acceptedFiles[0])}
              >
                {({ getRootProps, getInputProps }) => (
                  <Box {...getRootProps()} sx={{}}>
                    <input {...getInputProps()} />
                    {!image ? (
                      <ImagePlaceholder />
                    ) : (
                      <Box
                        sx={{
                          width: "100%",
                          height: "300px",
                          overflow: "hidden",
                          position: "relative",
                        }}
                      >
                        <img
                          src={URL.createObjectURL(image)}
                          alt="....Ink"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                )}
              </Dropzone>
            </Box>
          )}

          <Divider sx={{ margin: "1.25rem 0" }} />
          {/* IMAGE URL INPUT*/}
          {step === 1 && (
            <TextField
              label="Image URL"
              value={imageUrl}
              onChange={(e) => setImageUrl(e.target.value)}
              fullWidth
              variant="outlined"
              mt="1rem"
            />
          )}

          {/* STEP TWO */}
          {/* TITLE & DESCRIPTION */}

          {step === 2 && (
            <>
            <Text as='h1'>Details</Text>
            <Box display='flex' flexDirection={['column', 'column', 'column', 'row']} >





{/* LEFT */}
              <Box display='flex' flexDirection={['column']} flex={4} alignItems='left' paddingRight={['30px','30px','30px']}> 
              {/* Tattoo name */}
              <TextField
                label="Do you have a name for this tattoo?"
                placeholder="Gold Eyed Phoenix"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                // margin="normal"
                variant="outlined"
              />


              {/* Session Time */}
              <TextField
                label="How much time did the session take?"
                placeholder="6 hours"
                value={post.sessionTime}
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={(e) => setSessionTime(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Clock color="lightblue" />
                    </InputAdornment>
                  ),
                }}
              />

                {/* Session cost */}
              <TextField
                label="How much did the session cost?"
                placeholder="238"
                value={post.sessionPrice}
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={(e) => setSessionPrice(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MoneyIcon color="green" />
                    </InputAdornment>
                  ),
                }}
              />



<InputBase
               
                placeholder="write something about the tattoo..."
                onChange={(e) => setPost(e.target.value)}
                value={post}
                sx={{
                  width: "100%",
                  backgroundColor: "lightgray",
                  borderRadius: "10px",
                  padding: "1rem 2rem",
                  height: "200px",
                  mt: "75px",
                }}
              />


</Box>




{/* RIGHT */}
<Box flex={3}>

{image && (
    <Image
      src={URL.createObjectURL(image)}
      alt="Uploaded Image"
      style={{ width: '100%', height: 'auto', borderRadius: '20px', }}
    />
  )}




              <TattooStyleTagPicker
                value={selectedStyles}
                onChange={setSelectedStyles} 
              />

            

              </Box>
            </Box>
            </>
          )}
          

          {/* STEP BUTTONS */}
          {step === 1 ? (
            <Button
              sx={{
                marginTop: "20px",
                color: "white",
                backgroundColor: "black",
              }}
              variant="contained"
              onClick={handleNextStep}
            >
              Next
            </Button>
          ) : null}

          {step === 2 && (
            <>
              <Button onClick={handlePreviousStep}>Previous</Button>
              <Button onClick={handlePost}>Post</Button>
            </>
          )}
        </Box>

        {/* Success Modal */}
        <Modal
          open={showSuccessModal}
          onClose={() => setShowSuccessModal(false)}
        >
          <Box
            sx={{
              position: "absolute",
              width: [370, 500, 900],
              height: [500],
              backgroundImage: 'url("/SuccessModalBG.png")',
              backgroundPosition: "bottom",
              backgroundSize: "cover",
              backgroundAttachment: "fixed",
              backgroundRepeat: "no-repeat",
              bgcolor: "#ADDBFF",
              border: "2px solid #000",
              borderRadius: "20px",
              boxShadow: 24,
              p: 4,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
            >
              <IconButton
                onClick={() => setShowSuccessModal(false)}
                sx={{ color: "white" }}
              >
                <Clear />
              </IconButton>
            </Box>

            <Box
              marginBottom=""
              fontFamily="Ranchers"
            >
              <Typography
                variant="h1"
                color="white"
                fontFamily="ranchers"
                sx={{ marginTop: ["90px", "20px"] }}
              >
                Post Created Successfully
              </Typography>
            </Box>

            <img
              src="/SuccessPost.png"
              alt="Success Post! :D"
              style={{
                position: "absolute",
                bottom: 0,
                left: "50%",
                transform: "translateX(-50%)",
              }}
            />
          </Box>
        </Modal>
      </Box>
    </>
  );
};

export default PostWidgeTwo;
