import React, { useRef, useState, useEffect } from "react";
import {
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  styled,
  useMediaQuery,
  IconButton,
  Box,
} from "@mui/material";
import { Link } from "@chakra-ui/react";
import { Search, ArrowBack, ArrowForward } from "@mui/icons-material";
import PublicFeed from "./PublicFeed";
import DiscoverFeed from "./Discover";
import FlexBetween from "components/FlexBetween";
import UserGallery from "components/UserGallery/index";
import gsap from "gsap";



const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
  "& .MuiToggleButton-root.Mui-selected": {
    color: "gray",
    fontWeight: "bold",
    fontSize: "12px",
    border: "none",
  },
});

const FeedBar = () => {
  const [selectedFeedIndex, setSelectedFeedIndex] = useState(0);
  const [previousFeedIndex, setPreviousFeedIndex] = useState(0);
  const [isEntering, setIsEntering] = useState(false);
  const [isLeaving, setIsLeaving] = useState(false);

  const feeds = [
    { label: "Home", component: <PublicFeed /> },
    { label: "Community", component: <UserGallery /> },
    { label: "Discover", component: <PublicFeed /> },
  ];

  const navLinks = [
    {
      label: "Tattooing",
      url: "/explore",
    },
    {
      label: "Learn To Tattoo",
      url: "/Community",
    },
    {
      label: 'Video Gallery',
      url: '/gallery'
    },
    {
      label: 'Create an event',
      url:'/events'
    }
  ];

  const currentFeedRef = useRef(null);


// ! ANIMATION SUCKS IMPROVE LATER

  const switchFeed = (delta) => {
    setIsEntering(true);
    setTimeout(() => {
      setIsEntering(false);
      setIsLeaving(true);
      setTimeout(() => {
        setIsLeaving(false);
        setPreviousFeedIndex(selectedFeedIndex);
        const newIndex =
          (selectedFeedIndex + delta + feeds.length) % feeds.length;
        setSelectedFeedIndex(newIndex);
      }, 500);
    }, 500);
  };


  useEffect(() => {
    const tl = gsap.timeline();
    
    if (isEntering) {
      tl.to(currentFeedRef.current, { opacity: 0, y: -20, duration: 0.5 })
        .set(currentFeedRef.current, { y: 20 })
        .to(currentFeedRef.current, { opacity: 1, y: 0, duration: 0.5 });
    } else if (isLeaving) {
      tl.to(currentFeedRef.current, { opacity: 0, y: 20, duration: 0.5 })
        .set(currentFeedRef.current, { y: -20 })
        .to(currentFeedRef.current, { opacity: 1, y: 0, duration: 0.5 });
    }
  }, [isEntering, isLeaving]);
  const goBackToLastFeed = () => {
    setSelectedFeedIndex(previousFeedIndex);
  };
  const currentFeed = feeds[selectedFeedIndex];

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      mt="50px"
      flexDirection="column"
      ref={currentFeedRef}
    >
      {/* FEED NAV */}
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {navLinks.slice(0, 1).map((link, index) => (
            <Link key={index} to={link.url} color="black" marginRight="10px">
              {link.label}
            </Link>
          ))}
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {selectedFeedIndex > 0 ? (
            <IconButton onClick={() => switchFeed(-1)}>
              <ArrowBack style={{ color: "black", fontSize: "20px" }} />
            </IconButton>
          ) : null}
          <StyledToggleButtonGroup
            value={currentFeed.label}
            exclusive
            aria-label="text alignment"
          >
            <Typography
              key={currentFeed.label}
              value={currentFeed.label}
              style={{
              
                marginRight: "30px",
                marginLeft: "30px",
                fontSize: "30px",
                color: "black",
                fontFamily: "Passion One",
              }}
            >
              {currentFeed.label}
            </Typography>
          </StyledToggleButtonGroup>
          {selectedFeedIndex < feeds.length - 1 ? (
            <IconButton onClick={() => switchFeed(1)}>
              <ArrowForward style={{ color: "black", fontSize: "20px" }} />
            </IconButton>
          ) : null}
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {navLinks.slice(1, 2).map((link, index) => (
            <Link key={index} to={link.url} color="black" marginRight="10px">
              {link.label}
            </Link>
          ))}
        </Box>
      </Box>
      {currentFeed.component}
    </Box>
  );
};

export default FeedBar;
