import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setPosts } from "state";
import { Button } from "@chakra-ui/react";

const UserStatBar = ({ user, friends }) => {
  const dispatch = useDispatch();
  const posts = useSelector((state) => state.posts);
  const token = useSelector((state) => state.token);

  const getUserPosts = async () => {
    const response = await fetch(
      `http://localhost:8000/posts/${user._id}/posts`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` }
      }
    );
    const data = await response.json();
    dispatch(setPosts({ posts: data }));
  };

  useEffect(() => {
    getUserPosts(user._id);
  }, [user._id]);

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-evenly"
        alignItems="center"
        textAlign="center"
        bgcolor="#2A2D2E"
        borderRadius="20px"
        padding="0px 16px 0px 16px"
        mt="36px"
        minWidth="340px"
      >
        <Box display="flex" flexDirection="column" margin="10px">
          <Typography fontSize="20px" fontWeight="bold" color="white">
            {posts.length}
          </Typography>
          <Typography
            color="#848585"
            textTransform="uppercase"
            fontWeight="bold"
            fontSize="9px"
          >
            Posts
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" margin="10px">
          <Typography fontSize="20px" fontWeight="bold" color="white">
            {user.friends.length}
          </Typography>
          <Typography
            color="#848585"
            textTransform="uppercase"
            fontWeight="bold"
            fontSize="9px"
          >
            Followers
          </Typography>
        </Box>
      </Box>
      <Button
        border="none"
        padding="13px 19px"
        borderRadius="20px"
        cursor="pointer"
        position="relative"
        bottom="5px"
      >
        Message
      </Button>
    </>
  );
};

export default UserStatBar;
