import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Text, Button } from "@chakra-ui/react";
import WhiteLabelNav from "components/navbar/WhiteLabelNav";
import UserProfilePicture from "components/UserComponents/UserProfilePicture";
import { FaEye, FaUserPlus } from "react-icons/fa";
import Friend from "components/UserComponents/Friend";

const Index = () => {
  const [users, setUsers] = useState([]);
  const token = useSelector((state) => state.token);
  const userId = useSelector((state) => state.userId);

  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        const response = await fetch(
          `http://localhost:8000/users/${userId}/everyone`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setUsers(data);
        } else {
          throw new Error("Failed to fetch users");
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    if (token) {
      fetchAllUsers();
    }
  }, [token, userId]);

  return (
    <>
      <hr style={{ dropShadow: "20px 20px black" }} />
      <Box padding="50px">
        <Box
          height="100%"
          display="flex"
          flexDirection={["row"]}
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
        >
          {users.map((user, index) => (
            <Box
              key={index}
              height="300px"
              width="400px"
              border="solid black 2px"
              margin={["20px"]}
              borderRadius="20px"
              position="relative"
            >
              {/* Card heading */}
              <Box
                height="40%"
                width="100%"
                bgColor="lightblue"
                backgroundImage={user.bgPicturePath}
                backgroundPosition="center"
                backgroundSize="fit"
                borderRadius="20px"
              >
                {/* User profile picture */}
                <Box
                  position="absolute"
                  bottom="50%"
                  left="50%"
                  transform="translateX(-50%)"
                  zIndex="1"
                >
                  <UserProfilePicture
                    userPicturePath={user.picturePath}
                    size="100px"
                  />
                </Box>
              </Box>

              {/* User details */}
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                marginTop="3rem"
                flexDirection="column"
              >
                <Text as="h1">
                  {user.firstName} {user.lastName}
                </Text>
                {user.employedAt ? (
                  <Text as="h3" fontSize={["12px"]}>
                    {user.occupation} @ {user.employedAt}{" "}
                  </Text>
                ) : (
                  <Text as="h3">{user.occupation}</Text>
                )}
              </Box>

              <Box textAlign="center">
                <Text as="p">{user.bio}</Text>
              </Box>

              {/* Buttons for viewing profile and following */}
              <Box
                display="flex"
                justifyContent="space-around"
                marginTop="2rem"
              >
                <Button leftIcon={<FaEye />} colorScheme="teal">
                  View Profile
                </Button>
                <Button leftIcon={<FaUserPlus />} colorScheme="blue">
                  Follow
                </Button>
                <Friend />
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default Index;
