import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  IconButton,
  Divider,
} from '@mui/material';
import { SendOutlined } from '@mui/icons-material';

const CommentForm = ({ onSubmit, textColor, inputColor }) => {
  const [comment, setComment] = useState('');

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };



  const handleSubmit = () => {
    if (comment.trim() !== '') {
      onSubmit(comment); 
      setComment('');
    }
  };




  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    } else {
      return null
    }
      }

  return (
    <Box padding='10px' paddingTop='0px' style={{ overflowY: 'auto' }}>
      <TextField
        variant="outlined"
        label="Add a comment"
      onKeyDown={handleKeyPress}
        fullWidth
        rows={1}
        value={comment}
        onChange={handleCommentChange}
        InputProps={{ style: { color: inputColor, border: '#3C3F40 1px solid', borderRadius: '10px' } }} 
        InputLabelProps={{ style: { color: textColor } }}
      />
      <Box mt={1} display="flex" alignItems="center">
        <Button
          variant="contained"
          style={{color: 'black', background: 'white', marginTop: '20px', marginLeft: '10px'}}
          onClick={handleSubmit}
        >
          Post
        </Button>
      </Box>
      <Divider style={{ marginTop: '1rem' }} />
    </Box>
  );
};

export default CommentForm;
