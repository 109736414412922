import React, { useEffect } from 'react';
import { setPosts } from 'state';
import { Typography, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import WidgetWrapper from 'components/WidgetWrapper';
import UserImage from 'components/UserComponents/UserImage';

import FeedBar from '../FeedBar';
import Post from '../Post';
import PostWidget from 'scenes/widgets/PostWidget';
import { Public } from '@mui/icons-material';

const PublicFeed = ({ _id, isProfile = false }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const posts = useSelector((state) => state.posts); 
  const token = useSelector((state) => state.token);

  const getPosts = async () => {
    try {
      const response = await fetch('http://localhost:8000/posts', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      dispatch(setPosts({ posts: data }));
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  };

  useEffect(() => {
    getPosts();
  }, []);

  // Check if posts is an array before mapping
  if (!Array.isArray(posts)) {
    return null; // or handle the case when posts is not an array
  }

  const addNewPost = (newPost) => {
    dispatch(setPosts({ posts: [newPost, ...posts] }));
  };

  // Reverse the order of the posts
  const reversedPosts = [...posts].reverse();

  return (
    <>
      <Box display="flex" flexDirection="row" flexWrap='wrap' borderRadius="20px">
        {reversedPosts.map(
          ({
            _id,
            userId,
            username,
            firstName,
            lastName,
            description,
            title,
            location,
            picturePath,
            userPicturePath,
            likes,
            comments,
            createdAt,
          }) => (
            <PostWidget
              key={_id}
              username={username}
              postId={_id}
              postUserId={userId}
              name={`${firstName} ${lastName}`}
              description={description}
              title={title}
              location={location}
              picturePath={picturePath}
              userPicturePath={userPicturePath}
              likes={likes}
              comments={comments}
              createdAt={createdAt}
            />
          )
        )}
      </Box>
    </>
  );
};

export default PublicFeed;
