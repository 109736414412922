import React, { useState } from 'react';
// !Redux  imports
import { useSelector } from 'react-redux';
// ! Styles
import styles from './styles.module.scss';
// ! Chakra imports
import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { FaSearch } from "react-icons/fa";

const Searchbar = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredPosts, setFilteredPosts] = useState([]);

  const posts = useSelector((state) => state.posts);

  const handleSearch = () => {
    const searchValue = searchTerm.toLowerCase().trim();
    const filtered = posts.filter((post) => {
      if (!post || !post.title || !post.description) {
        return false;
      }
      const title = post.title.toLowerCase();
      const description = post.description.toLowerCase();
      return title.includes(searchValue) || description.includes(searchValue);
    });

    setFilteredPosts(filtered);
  };

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className={styles.container}>
      <InputGroup>
        <InputLeftElement
          pointerEvents="none"
          height="100%"
          children={<FaSearch color="gray.500" />}
        />
        <Input
          className={styles.searchInput}
          type="text"
          value={searchTerm}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          placeholder="Search"
          paddingLeft="40px"  // Adjust padding to create space for the icon
        />
      </InputGroup>
    </div>
  );
};

export default Searchbar;
