import { useState } from "react";
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Typography,
  useTheme
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLogin } from "state";
import Dropzone from "react-dropzone";
import FlexBetween from "components/FlexBetween";
import { Flex, Image, Text } from "@chakra-ui/react";
import WhiteLabelNav from "components/navbar/WhiteLabelNav";
import TattooStyleTagPicker from "components/General/TagPicker";


// SUCESS PAGE
import RegisterSuccess from "scenes/register/RegisterSuccess";

const registerSchema = yup.object().shape({
  username: yup.string().required("Username Is Required"),
  firstName: yup.string().required("required"),
  lastName: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),
  password: yup.string().required("required"),
  location: yup.string().required("required"),
  occupation: yup.string().required("required"),
  picture: yup.string().required("required")
});

const initialValuesRegister = {
  username: "",
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  location: "",
  occupation: "",
  picture: "",
  tattooStyles: [],
};

const Form = () => {
  const [pageType, setPageType] = useState("register");
  const [selectedImage, setSelectedImage] = useState(null);
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const isRegister = pageType === "register";

  const register = async (values, onSubmitProps) => {
    const formData = new FormData();
    for (let value in values) {
      formData.append(value, values[value]);
    }
    formData.append("picturePath", values.picture.name);

    const savedUserResponse = await fetch(
      "http://localhost:8000/auth/register",
      {
        method: "POST",
        body: formData
      }
    );
    const savedUser = await savedUserResponse.json();
    onSubmitProps.resetForm();
    if (savedUser) {
      setPageType("registerSuccess");
    }
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    console.log("Registration values:", values);
    await register(values, onSubmitProps);
    
  };







  return (

    
    <>
    <WhiteLabelNav/>

  {pageType === 'register' && (
    <Box>
    <Box display="flex" justifyContent="center" marginBottom="20px" color='white !important' padding={['20px']}>
    <Text as='h1' fontSize={['18px', '18px', '25px']} fontFamily='Passion One' color='black'>Register</Text>
      </Box>

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValuesRegister}
        validationSchema={registerSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          resetForm
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
padding:['25px', '25px', '25px']              }}
            >

              {/* REGISTER */}
              {isRegister && (
                <>
                  <TextField
                    label="username"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.username}
                    name="username"
                    error={
                      Boolean(touched.username) && Boolean(errors.username)
                    }
                    helperText={touched.username && errors.username}
                    sx={{ gridColumn: "span 2",
                    
                  }}
                  />

                  <TextField
                    label="First Name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.firstName}
                    name="firstName"
                    error={
                      Boolean(touched.firstName) && Boolean(errors.firstName)
                    }
                    helperText={touched.firstName && errors.firstName}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    label="Last Name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.lastName}
                    name="lastName"
                    error={
                      Boolean(touched.lastName) && Boolean(errors.lastName)
                    }
                    helperText={touched.lastName && errors.lastName}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    label="Location"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.location}
                    name="location"
                    error={
                      Boolean(touched.location) && Boolean(errors.location)
                    }
                    helperText={touched.location && errors.location}
                    sx={{ gridColumn: "span 4" }}
                  />
                  <TextField
                    label="Occupation"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.occupation}
                    name="occupation"
                    error={
                      Boolean(touched.occupation) && Boolean(errors.occupation)
                    }
                    helperText={touched.occupation && errors.occupation}
                    sx={{ gridColumn: "span 4" }}
                  />
                  <Box
                    gridColumn="span 4"
                    borderRadius="5px"
                  >
                 <Dropzone
        acceptedFiles=".jpg,.jpeg,.png"
        multiple={false}
        onDrop={(acceptedFiles) => {
          setFieldValue("picture", acceptedFiles[0]);
          setSelectedImage(URL.createObjectURL(acceptedFiles[0]));
        }}
                    >

                      {/* DROPZONE DIV */}

                      {({ getRootProps, getInputProps }) => (
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      
                          {...getRootProps()}
                          border={`1px solid gray`}
                          borderRadius='10px'
                          p="1rem"
                          sx={{ "&:hover": { cursor: "pointer" } }}
                        >
                          <input {...getInputProps()} />
                          {selectedImage ? (

<Flex direction='column' justifyContent='center' alignItems='center'>
<Image src={selectedImage} alt='User Avatar' width= {['15%', '15%', '20%']} height={['15%', '15%', '20%']}  style={{objectFit: 'cover' }} />
<Text as='h3' marginTop='2rem' fontSize={['14px']}>{values.picture.name}</Text>
</Flex>

) : (
<Flex direction='column' justifyContent='center' alignItems='center'>
  <Image src='./Face-character.png'  width= {['15%', '15%', '20%']} alt="Placeholder" style={{ objectFit: 'cover' }} />
  <Text as='h3'>Add your own image</Text>
  </Flex>
)}

                        </Box>
                      )}
                    </Dropzone>
                  </Box>
                </>
              )}
              <TextField
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={Boolean(touched.email) && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                label="Password"
                type="password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                name="password"
                error={Boolean(touched.password) && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                sx={{ gridColumn: "span 4" }}
              />

<TattooStyleTagPicker   value={values.tattooStyles || []}
  onChange={(selectedStyles) => setFieldValue("tattooStyles", selectedStyles)}/>


              
            </Box>

            {/* BUTTONS */}
            <Box p='10px'>
              <Button
              
                fullWidth
                type="submit"
                sx={{
                  textTransform: 'capitalize',
                  m: "2rem 0",
                  p: "1rem",
                  backgroundColor: 'black',
                  color: 'white',
                  "&:hover": { color: palette.primary.main }
                }}
              >
                Register Now
              </Button>

              <Typography
                onClick={() => {
                  setPageType("login");
                  resetForm();
                }}
                sx={{
                  textDecoration: "underline",
                  "&:hover": {
                    cursor: "pointer",
                    color: palette.primary.light
                  }
                }}
              >
                Already have an account? Login here.
              </Typography>
            </Box>
          </form>
        )}
      </Formik>
      </Box>
      )}
{pageType === 'registerSuccess' && <RegisterSuccess/> }

    </>
  );
};

export default Form;