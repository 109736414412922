import React, { useEffect, useState, useRef } from 'react';
import { setPosts } from 'state';
import { Typography, Box, ListItem, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import WidgetWrapper from 'components/WidgetWrapper';
import UserImage from 'components/UserComponents/UserImage';
import FeedBar from '../FeedBar';
import './DiscoverFeed.css';
import Post from '../Post';
import PostWidget from 'scenes/widgets/PostWidget';
import { Public } from '@mui/icons-material';
import DiscoverWidget from 'scenes/widgets/DiscoverWidget';
import {Text, UnorderedList} from '@chakra-ui/react';



// Input
import SearchBar from 'components/Inputs/Searchbar';
// Widgets
import EventWidget from 'components/General/Widgets/EventWidget';
import { Container } from '@chakra-ui/react';

// Import masonry-simple

const DiscoverFeed = ({ _id, isProfile = false }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const posts = useSelector((state) => state.posts);
  const token = useSelector((state) => state.token);
  const [filteredPosts, setFilteredPosts] = useState(posts);
  const [searchTerm, setSearchTerm] = useState('');
  

  const getPosts = async () => {
    try {
      const response = await fetch('http://localhost:8000/posts', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch posts');
      }
      const data = await response.json();
      dispatch(setPosts({ posts: data }));
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  };

  useEffect(() => {
    getPosts();
  }, []);





  const tattooStyles = [
    'Traditional',
    'Colored',
    'Old School',
    "Japanese",
    "Dotwork",
    "Illustrative",
    "Trash Polka",
    "Abstract",
 
  ]
  
  
  const renderEmptySearch = () => {
    if (posts.length > 0 && searchTerm.trim() !== '' && filteredPosts.length === 0) {
      return <img src="./no-results.png" alt="no results found" />;
    }
    return null;
  };


  return (
    <>
      <Container>
        <Box>
          <Box marginTop={['100px']}>
            <EventWidget />
            <SearchBar posts={posts} setFilteredPosts={setFilteredPosts}/>





<Box padding='20px' display='flex' flexDirection='column' paddingLeft={['0','3rem','3rem']} alignItems={'center'} justifyContent='center'>
<Text as='h1' fontSize={['25px']} marginBottom='1rem' paddingLeft='16px' >Discover by style</Text>
<Box width='900px' display='flex' flexWrap='wrap'>
{tattooStyles.map((tattoo, index) => (
  <Button key={index} style={{
    margin: '10px', backgroundColor: '#F8F7F4', color: 'gray', padding: '10px', borderRadius: '20px'
  }}>{tattoo}</Button>
))}
</Box>
<Box>


</Box>


</Box>

            <div
              className='masonry'
            >
              {filteredPosts.map((post) => (
                <div key={post._id} className='masonry-item'>
                  <PostWidget key={post._id} {...post} />
                </div>
              ))}
            </div>
            {renderEmptySearch()}
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default DiscoverFeed;
