import React, { useState } from "react";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";



const TattooStyleTagPicker = ({ value =[], onChange }) => {
  const [inputValue, setInputValue] = useState("");  
  const [suggestedStyles, setSuggestedStyles] = useState([
    "Traditional",
    "Realism",
    "Black and Gray",
    "Neo-Traditional",
    "Watercolor",
    "Japanese",
    "Geometric",
    "Minimalist",
    "Abstract",
    "Tribal",
    "New School",
    "Illustrative",
    "Portrait",
    "Dotwork",
    "Biomechanical",
  ]);

  const handleAddStyle = () => {
    if (inputValue.trim() !== "") {
      if (!value.includes(inputValue)) {
        onChange([...value, inputValue]);
      }
      setInputValue("");
    }
  };

  const handleRemoveStyle = (styleToRemove) => {
    const updatedStyles = value.filter((style) => style !== styleToRemove);
    onChange(updatedStyles);
  };

  return (
    <div>
      <div style={{ paddingTop: "30px", paddingBottom: "30px" }}>
        {value.map((style) => (
          <Chip
            key={style}
            label={style}
            onDelete={() => handleRemoveStyle(style)}
            style={{
              margin: "4px",
              backgroundColor: "black",
              color: "white",
             padding: '10px 13px',
            }}
            deleteIcon={
              <span
                style={{ color: "white", fontSize: '10px' }} 
              >
                &#x2715;
              </span>
            }
          />
        ))}
      </div>
      <TextField
        label="Add some custom tags"
        variant="outlined"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            handleAddStyle();
          }
        }}
        onBlur={handleAddStyle}
        fullWidth
      />
      <div style={{ paddingTop: "30px" }}>
        <p style={{ paddingBottom: "15px" }}>Suggested Tattoo Styles:</p>
        {suggestedStyles.map((style) => (
          <Chip
            key={style}
            label={style}
            onClick={() => {
              if (!value.includes(style)) {
                onChange([...value, style]);
              }
            }}
            style={{ margin: "4px", cursor: "pointer" }}
          />
        ))}
      </div>
    </div>
  );
};

export default TattooStyleTagPicker;
