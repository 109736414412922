import React, { useState } from 'react';
import { Box, Container, Input, Text } from '@chakra-ui/react';
import { setPosts } from 'state';
import { IoSearch } from 'react-icons/io5';






const SearchBar = ({ posts, setFilteredPosts }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = () => {
    const searchValue = searchTerm.toLowerCase().trim();

    const filtered = posts.filter((post) => {
      if (!post || !post.title || !post.description) {
        return false;
      }
      const title = post.title.toLowerCase();
      const description = post.description.toLowerCase();
      return title.includes(searchValue) || description.includes(searchValue);
    });

    setFilteredPosts(filtered);
  };

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };





  return (
    <Container padding="20px">
      <Box display="flex" justifyContent="center" alignItems="center" marginTop="20px" flexDirection="column">
        <Text as="h1" fontSize={['35px']} paddingBottom="3rem">
          Search for anything
        </Text>
        <Input
          className="search-input"
          type="text"
          value={searchTerm}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          placeholder="Search...."
          style={{
            backgroundColor: '#d9d9d9',
            borderRadius: '20px',
            height: '90px',
            padding: '10px',
            fontSize: '16px',
            paddingLeft: '40px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19)',
            transition: 'box-shadow 0.3s ease',
          }}
        />

      </Box>
    </Container>
  );
};

export default SearchBar;
