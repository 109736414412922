import React, { useState } from "react";
import {TextField, Typography, Grid, Button} from "@mui/material";
import {Box} from '@chakra-ui/react'
import { Formik  } from "formik";
import * as yup from "yup";
import Dropzone from "react-dropzone";
import TattooStyleTagPicker from "components/General/TagPicker";
import WhiteLabelNav from "components/navbar/WhiteLabelNav";
import Switch from '@mui/material/Switch';
import Newsletter from "components/General/Newsletter";
import Footer from 'components/General/Footer';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const registerSchema = yup.object().shape({
  username: yup.string().required("Required"),
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  email: yup.string().email("Invalid email").required("Required"),
  bio: yup.string(),
  password: yup.string().required("Required"),
  location: yup.string().required("Required"),
  about: yup.string(),
  featuredWork: yup.array(),
  employedAt: yup.string(),
  picture: yup.string().required("Required"),
  tattooStyles: yup.array(),
});

const initialValuesRegister = {
  username: "",
  firstName: "",
  lastName: "",
  email: "",
  bio: "",
  password: "",
  location: "",
  about: "",
  featuredWork: [],
  employedAt: "",
  picture: "",
  tattooStyles: [],

};


const ArtistRegisterForm = () => {
 
  const [selectedTattooStyles, setSelectedTattooStyles] = useState([]);
  const [selectedSpecialties, setSelectedSpecialties] = useState([]);
const [selectedFiles, setSelectedFiles] = useState([]);
  const [imageSrc, setImageSrc] = useState(null);
const dispatch = useDispatch();
const navigate = useNavigate();






  const register = async (values, onSubmitProps) => {
    const formData = new FormData();
    for(let value in values) {
        formData.append(value,values[value]);
        formData.append("password", values.password);
    }
    formData.append("picturePath", values.picture)

    try {
        const savedUserResponse = await fetch (
            'http://localhost:8000/auth/register',
            {
                method: "POST",
                body: formData,
            }
        );
        
        if(savedUserResponse.ok) {
            const savedUser = await savedUserResponse.json();
            alert('Registration successful', savedUser);
            onSubmitProps.resetForm();
        } else {
            console.error('registration failed', savedUserResponse.statusText);
            
        }
    } catch(err) {
        console.error(err)
    }
  };


  // !!USE THIS LATER
  const handleFormSubmit = async (values, onSubmitProps,event) => {

     register(values, onSubmitProps);
  };


  // const handleFormSubmit = async (values, onSubmitProps) => {
  //   console.log("Form Values:", values)
  //   const formData = new FormData();
  //   for (let value in values) {
  //     if (value === "tattooStyles") {
  //       // Convert the tattooStyles array to a comma-separated string before sending it
  //       formData.append(value, values[value].join(","));
  //     } else {
  //       formData.append(value, values[value]);
  //     }
  //   }
  //   for (let value in values) {
  //     formData.append(value, values[value]);
  //   }
  
  //   try {
  //     const savedUserResponse = await fetch(
  //       "http://localhost:3001/auth/register",
  //       {
  //         method: "POST",
  //         body: formData,
  //       }
  //     );
  
  //     if (savedUserResponse.ok) {
  //       const savedUser = await savedUserResponse.json();
  //       alert("Registration successful", savedUser);
  //       onSubmitProps.resetForm();
  //     } else {
  //       const errorResponse = await savedUserResponse.json();
  //       setSnackbarMessage(`Error: ${errorResponse.message}`);
  //       setOpenSnackbar(true); // Display the snackbar on error
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setSnackbarMessage("Error: Registration failed");
  //     setOpenSnackbar(true); // Display the snackbar on error
  //   }
  // };





  
  const handleSpecialtiesChange = (newValues) => {
    // Update the selected specialties in the state
    setSelectedSpecialties(newValues);
  };


  const label = { inputProps: { 'aria-label': 'Switch demo' } };
const removeFile = (index, event) => {
  event.stopPropagation(); //This stops dropZone from opening file browser
  const updatedFiles = [...selectedFiles];
  updatedFiles.splice(index, 1);
  setSelectedFiles(updatedFiles);
};

  return (
    <div
    style={{
      width: "100%",
      height: "100%",
      position: "relative",
      background: "white",
      overflowY: "auto",
    }}
  >

    <Box
      style={{
        width: "100%",
        height: "100%",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: 10,
        border: "0.50px #635D5D solid",
        textAlign: "center",
        overflowY: "auto",
        padding: '25px'
      }}
    >
      <WhiteLabelNav/>
      <Box  padding={['50px','50px', '80px', '80px']}>
        <Typography
          variant="h1"
          fontFamily="Montserrat"
          fontWeight="bold"
          fontSize={["19px", "24px", "24px"]}
          paddingTop="15px"
          paddingBottom='15px'
        >
          Unlock Your Creative Potential: Join as a New Artist
        </Typography>
        <Typography
          variant="p"
          fontFamily="Montserrat"
          fontWeight="bold"
          fontStyle="italic"
          paddingTop='30px'
          color='gray'
        >
          Enter some details & create your profile below!
        </Typography>
      </Box>

   {/* Form Container */}
      <Box
        display="flex"
        alignItems="left"
        flexDirection="column"
        textAlign="left"
        paddingTop='0'
        padding={[null,null,null,'90px']}
       
      >
        <Typography
          variant="h2"
          className="register_title"
          fontSize={["20px", "24px", "24px"]}
        >
          Personal Details
        </Typography>
        <Typography variant="p" fontSize={["12px"]}>
        This information is for the creation of your account. You can choose to display this information or not.
        </Typography>

        <Formik
          onSubmit={(values,onSubmitProps) => handleFormSubmit(values,onSubmitProps)}
          initialValues={initialValuesRegister}
          validationSchema={registerSchema}

        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={[5,4]} sx={{paddingTop: '35px'}}>
                <Grid item xs={12} md={2}>
                  <TextField
                    label="Pick a username"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.username}
                    name="username"
                    error={Boolean(touched.username) && Boolean(errors.username)}
                    helperText={touched.username && errors.username}
                    InputProps={{ style: { color: "black" } }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    label="First name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.firstName}
                    name="firstName"
                    error={Boolean(errors.firstName)}
                    helperText={errors.firstName}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    label="Last Name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.lastName}
                    name="lastName"
                    error={Boolean(errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                    
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    label="What is your email?"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    name="email"
                    error={Boolean(errors.email)}
                    helperText={errors.email}

                       fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    label="Create a password"
                    type="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    name="password"
                    error={Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                    fullWidth
                  />
                </Grid>
    
              </Grid>





{/* PROFILE INPUTS */}

      <Box>
      <Typography
          variant="h2"
          className="register_title"
          fontSize={["20px", "24px", "24px"]}
        >
          Profile
        </Typography>
        <Typography variant="p" fontSize={["12px"]}>
          This information can help us share relevant content with you or
          connect you with the right people, events, and companies.
        </Typography>
      </Box>

      {/* PROFILE PICTURE  */}
      <Box
                  gridColumn="span 4"
                  textAlign="center"
                  borderRadius="5px"
                  p="3rem"
                  marginTop="1rem"
                >
                 
                  <Dropzone
                    acceptedFiles=".jpg,.jpeg,.png,."
                    multiple={false}
                    onDrop={(acceptedFiles) => {
                      const selectedImage = acceptedFiles[0];
                      setFieldValue("picture", selectedImage);

                      // Use FileReader to display the selected image as the avatar
                      const reader = new FileReader();
                      reader.onload = (e) => {
                        const imageSrc = e.target.result;
                        setImageSrc(imageSrc); // Set the image source in your component's state
                      };
                      reader.readAsDataURL(selectedImage);
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <Box
                        {...getRootProps()}
                        p="1rem"
                        sx={{ "&:hover": { cursor: "pointer" } }}
                      >
                        <input {...getInputProps()} />
                        {!values.picture ? (
                          <>

                                 <Typography variant='h3' alignSelf='left' textAlign='left'>Avatar</Typography>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              textAlign="center"
                              borderRadius="50%"
                              marginBottom="1rem"

                            >
                              
                              {/* Placeholder avatar image */}
                              {/* <img
                                // src="/Avatar.png"
                                alt="Placeholder"
                                style={{ width: "100%", height: "100%" }}
                              /> */}
                            </Box>
                            <Button
  variant="outlined"
  style={{ color: 'black',backgroundColor: 'transparent', borderColor: 'black' }} // Set text color and border color to white
  paddingTop="10px"
>
  Upload A Profile Picture
</Button>
                          </>
                        ) : (
                          <>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              textAlign="center"
                              borderRadius="50%"
                              marginBottom="1rem"
                              height="400px"
                              width='400px'
                            >
                              {/* Uploaded avatar image */}
                              <img
                                src={imageSrc}
                                alt="Avatar"
                                style={{ width: "100%", height: "100%" }}
                              />
                            </Box>
                            <Button
                              variant="contained"
                              color="primary"
                              style={{background: 'transparent', color: 'black'}}
                              paddingTop="10px"
                            >
                              Change Profile Picture
                            </Button>
                          </>
                        )}
                      </Box>
                    )}
                  </Dropzone>
                </Box>
              
        

{/* TAGS */}
<Box textAlign='left'>
<Typography
          variant="h2"
          className="register_title"
          fontSize={["20px", "24px", "24px"]}
          textAlign='left'
        >
          Tags
        </Typography>
        <Typography variant="p" fontSize={["12px"]}>
        Tags help others understand your interests and skills. They make it easier for users to find and connect with people who share similar interests or have expertise in specific areas.
        </Typography>
                          <TattooStyleTagPicker   value={selectedTattooStyles}
                onChange={setSelectedTattooStyles}/>

</Box>


{/* SHOW STATS ON PROFILE */}
<Box textAlign='left'>
<Typography
          variant="h2"
          className="register_title"
          fontSize={["20px", "24px", "24px"]}
          textAlign='left'
        >
          Show stats on profile
        </Typography>
        <Typography variant="p" fontSize={["12px"]}>
        For example: followers, following, articles count. Note: The stats will always be visible to you.
        </Typography>
        <Switch {...label} defaultChecked color='warning'/>

</Box>


{/* TELL US ABOUT YOURSELF */}
<Box textAlign='left'>
  <Typography
    variant="h2"
    className="register_title"
    fontSize={["20px", "24px", "24px"]}
    textAlign='left'
  >
    Tell us about yourself
  </Typography>
  <Typography variant="p" fontSize={["12px"]}>
    Write a small entry on who you are and what interests you have
  </Typography>
  <TextField
    label="About Me"
    placeholder="Brief entry about you"
    type='text'
    multiline
    fullWidth
    rows={4}
    value={values.about}  
    onChange={handleChange} 
  />
</Box>




<Box textAlign="left">
        <Typography
          variant="h2"
          className="register_title"
          fontSize={["20px", "24px", "24px"]}
          textAlign="left"
        >
          Featured Work
        </Typography>
        <Box textAlign="left">
  
        <Typography variant="p" fontSize={["12px"]}>
         Display some of your favorite work on your profile!
        </Typography>

        <Box display='flex' justifyContent='center' alignItems='center' textAlign='center' marginTop='30px'>
        <Button
              fullWidth
              type="submit"
              onClick={register}
              sx={{
                m: "2rem 0",
                p: "1rem",
             
              }}
            >
     Sign Me Up Baby
            </Button>
        </Box>
      </Box>
      
      </Box>





</form>





)}

</Formik>
</Box>


<Newsletter/>
<Footer/>
    </Box>
    <Box>

    </Box>
  </div>
  );
};





export default ArtistRegisterForm;
