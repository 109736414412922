import { Box } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

const Timestamp = ({ createdAt }) => {
  const [timeAgo, setTimeAgo] = useState('');
const [isHovered, setIsHovered] = useState(false);
  useEffect(() => {
    const calculateTimeAgo = () => {
      const currentTime = new Date().getTime();
      const postTime = new Date(createdAt).getTime();
      const timeDifference = currentTime - postTime;

      const minutes = Math.floor(timeDifference / 60000);
      const hours = Math.floor(timeDifference / 3600000);
      const days = Math.floor(timeDifference / 86400000);

      if (minutes < 60) {
        setTimeAgo(`${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`);
      } else if (hours < 24) {
        setTimeAgo(`${hours} ${hours === 1 ? 'hour' : 'hours'} ago`);
      } else {
        setTimeAgo(`${days} ${days === 1 ? 'day' : 'days'} ago`);
      }
    };

    calculateTimeAgo();

    // Update time every minute
    const interval = setInterval(calculateTimeAgo, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [createdAt]);

  return <Box  
  color= 'gray'
  onMouseEnter={() => {
    setIsHovered(true);
  }} style={{ fontSize:'12px'}}>{timeAgo}</Box>;
};

export default Timestamp;
