import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import authReducer from "./state";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";

// CHAKRA
import { ChakraProvider, extendTheme, CSSReset } from "@chakra-ui/react";


import "./Styles/index.css"

// Fonts
import '@fontsource/poppins';
// passion-one -> Passion One
import "@fontsource/passion-one";
import "@fontsource/ranchers";
import "@fontsource/lily-script-one";
import "@fontsource-variable/darker-grotesque";
import "@fontsource-variable/fira-code";
import "@fontsource/kavoon";
import '@fontsource/merriweather';
// Supports weights 300-800
// ?  font-family: 'Open Sans Variable', sans-serif;
import '@fontsource-variable/open-sans';

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/integration/react";
import "boxicons";


const theme = extendTheme();

const persistConfig = { key: "root", storage, version: 1 };
const persistedReducer = persistReducer(persistConfig, authReducer);
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CSSReset />
    <ChakraProvider theme={theme}>
    
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistStore(store)}>

          <App />
        </PersistGate>
      </Provider>
    </ChakraProvider>
  </React.StrictMode>
);
