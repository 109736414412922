import React, { useState } from 'react';
import { styled } from '@mui/system';
import {
  Box,
  Typography,
  IconButton,
  Divider,
  useTheme,
  Collapse,
  List,
  ListItem,
  ListItemText,
  TextField,
  Button
  
} from '@mui/material';


import WidgetWrapper from 'components/WidgetWrapper';

import {
    ChatBubbleOutlineOutlined,
    FavoriteBorderOutlined,
    FavoriteOutlined,
    ShareOutlined,
  } from "@mui/icons-material";
import { setPost } from "state";
import FlexBetween from 'components/FlexBetween';
import { useDispatch, useSelector } from "react-redux";
import Timestamp from 'components/Timestamp';
import SearchBar from 'components/Inputs/Searchbar';




const CardContainer = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  borderRadius: '15px 20px',
}));

const ContentContainer = styled(Box)({
  marginBottom: '2rem',
});

const FirstRowContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  padding: '20px 20px',
  borderRadius: '15px 20px',
  justifyContent: 'space-between',
}));

const UserImage = styled(Box)({
  width: '45px',
  height: '45px',
  borderRadius: '50%',
  backgroundColor: '#fff',
});

const Post = ({
  postId,
  postUserId,
  name,
  description,
  location,
  picturePath,
  likes,
  comments,
  createdAt,
}) => {
    const [isComments, setIsComments] = useState(false);
    const [commentText, setCommentText] = useState('');
    const dispatch = useDispatch();
    const token = useSelector((state) => state.token``);
    const loggedInUserId = useSelector((state) => state.user._id);
    const isLiked = Boolean(likes[loggedInUserId]);
    const likeCount = Object.keys(likes).length;
    

// MOVE TO PAGES DIRECTORY

// COmments functions
const handleCommentSubmit = async () => {
    const response = await fetch(`http://localhost:8000/posts/${postId}/comment`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId: loggedInUserId, comment: commentText }),
    });

    if (response.ok) {
      const updatedPost = await response.json();
      dispatch(setPost({ post: updatedPost }));
      // Reset the comment text field
      setCommentText('');
    }
  };

  const handleToggleComments = () => {
    setIsComments(!isComments);
  };

  const handleCommentTextChange = (event) => {
    setCommentText(event.target.value);
  };

    const patchLike = async () => {
        const response = await fetch(`http://localhost:8000/posts/${postId}/like`, {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId: loggedInUserId }),
        });
        const updatedPost = await response.json();
        dispatch(setPost({ post: updatedPost }));
      };

  return (
    <Box bgcolor='#2A2D2E' mb='30px' mt='50px' borderRadius='20px' >
      
    
    <WidgetWrapper m="2rem 0">
{/* <img src='/fakeimage.' alt='img'/> */}
<Box display='flex' height='100%'>
{/* <UserImage picturePath={picturePath}/> */}
<Box display='flex' 
flexDirection='column'
ml='10px'
>
<Typography>{name}</Typography>
<Timestamp createdAt={createdAt}/>
</Box>
</Box>
 
    {/* {picturePath && (
      <img
      alt='tester'
        width="100%"
        height="auto"
        mb='30px'
        style={{ borderRadius: "0.75rem", marginTop: "0.75rem" }}
        src={`http://localhost:3001/assets/${picturePath}`}
      />
    )} */}
     <Typography color='orange' sx={{ mt: "1rem" }}>
      {description}
    </Typography>
    <FlexBetween mt="0.25rem">
        
      <FlexBetween gap="1rem">
        <FlexBetween gap="0.3rem">
          <IconButton onClick={patchLike}> 
            {isLiked ? (
              <FavoriteOutlined sx={{ color: 'blue' }} />
            ) : (
              <FavoriteBorderOutlined />
            )}
          </IconButton>
          <Typography>{likeCount}</Typography>
        </FlexBetween>






        {/* COMMENTS FORM */}
      {isComments && (
        <Box mt="1rem" >
          <Divider />
          {/* <Typography variant="h6" sx={{ mt: '1rem' }}>
            Add a Comment
          </Typography> */}
          <Box display="flex" flexDirection='column'>
            <TextField
              fullWidth
              variant="outlined"
              label="Your Comment"
              value={commentText}
              onChange={handleCommentTextChange}
              sx={{ mr: '1rem' }}
            />
            <Button variant="contained" onClick={handleCommentSubmit}>
              Submit
            </Button>
          </Box>
        </Box>
      )}



        <FlexBetween gap="0.3rem">
          <IconButton onClick={() => setIsComments(!isComments)}>
            <ChatBubbleOutlineOutlined />
          </IconButton>
          <Typography>{comments.length}</Typography>
        </FlexBetween>
      </FlexBetween>

      <IconButton>
        <ShareOutlined />
      </IconButton>
    </FlexBetween>
    {isComments && (
      <Box mt="0.5rem">
        {comments.map((comment, i) => (
          <Box key={`${name}-${i}`}>
            <Divider />
            <Collapse in={isComments}>
       
        </Collapse>

            <Typography sx={{ color: 'orange', m: "0.5rem 0", pl: "1rem" }}>
              {comment}
            </Typography>
            <Collapse in={isComments}>
       
        </Collapse>

          </Box>
        ))}
        <Divider />
      </Box>
    )}
  </WidgetWrapper>
  </Box>
);
};

export default Post;
