import React from 'react';
import {Box, IconButton, Typography} from '@mui/material';

// Icons
import TagIcon from '@mui/icons-material/Tag';
import PaidIcon from '@mui/icons-material/Paid';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';







const PostStats = ({post, postId}) => {


    const stats = [
        {
            label: 'SKU',
            icon: <TagIcon/>,
            setting: postId,
            color: 'orange'
    
        },
        {
            label: "Session Price",
            icon: <PaidIcon/>,
            setting: ` $${post.sessionPrice}`,
            color: 'green'
        }, 
        {
            label: 'Session Time',
            icon: <AccessTimeIcon/>,
            setting: post.sessionTime,
            color: 'yellow'
        },
        {
            label: 'Styles incorporated',
            icon: <MeetingRoomIcon/>,
            setting: '3',
            color: 'blue'
        }
    ]





  return (

<Box position='relative' top={['50px']}> 

<Box  position='absolute'  width='49%' >
{/* Left side icon/text */}
<Box display='flex' flexDirection='column'  width='100%'>
{stats.map((item, index) => (
    <Box key={index} display='flex' alignItems='center'>
    <IconButton style={{color: 'black'}}>{item.icon}</IconButton>
    <Typography variant='p'>{item.label}</Typography>
    <Box marginLeft='auto'>
<Typography variant='p'>{item.setting}</Typography>
        </Box>
</Box>
))}
</Box>


</Box>




</Box>
  )
}

export default PostStats
