import React from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const CustomButton = ({ text, src }) => {
  return (
    <>
      <Button
        type="submit"
        sx={{
          mt: 2,
          width: "100%",
          borderRadius: "10px",
          backgroundColor: "black",
          color: "white",
          padding: "15px",
          transition: "background-color 0.4s, color 0.4s",
          "&:hover": {
            backgroundColor: "white",
            color: "black",
          },
        }}
      >
        <Link href={src}>{text}</Link>
      </Button>
    </>
  );
};

export default CustomButton;
