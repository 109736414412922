import React, { useState, useEffect } from "react";
import { Box, Link } from "@chakra-ui/react";
import { useSelector } from "react-redux";



// Components
import {Text} from '@chakra-ui/react';

const EventWidget = () => {
const token = useSelector((state) => state.token);
const [event, setEvent] = useState({});






const getFeaturedEvent = async () => {


// * Cover edge cases
  const response = await fetch('http://localhost:8000/events', {
    method: 'GET',
    headers: {Authorization: `Bearer ${token}`},
  });
  const data = await response.json();
  const featuredEvent = data.find((event) => event.isFeatured);
  setEvent(featuredEvent);
  return featuredEvent;
}


useEffect(() => {
  getFeaturedEvent()
}, [])





  const [isHovered, setIsHovered] = useState(false);

  return (
    <div style={{ maxWidth: "900px", margin: "0 auto" }}>
      <Link href="/events">


        
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="relative"
          width="100%"
          height="210px"
          borderRadius="25px"
          overflow="hidden"
          boxShadow={isHovered ? "none" : "20px 20px 20px rgba(0,0,0,0.3)"}
          transform={isHovered ? "translateY(-5px)" : "none"}
          transition="transform 0.3s ease-in-out"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          cursor="pointer"
        >
          <Box
            backgroundImage="./tattooExpo.png"
            backgroundRepeat="no-repeat"
            backgroundPosition="center"
            backgroundSize="cover"
            width="100%"
            height="100%"
            color='white'
>



          </Box>
          <Box
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
            backgroundColor="rgba(0,0,0,0.3)"
            opacity={isHovered ? 0 : 1}
            transition="opacity 0.3s ease-in-out"
            pointerEvents="none"
          />



        </Box>
      </Link>
    </div>
  );
};

export default EventWidget;
