import React from 'react';
import { Box, Typography, Link, Button } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';

const footerLinks = [
  {
    name: 'Privacy Policy',
    label: 'Privacy Policy',
    url: '/privacypolicy',
  },
  {
    name: 'Code of Conduct',
    label: 'Code of Conduct',
    url: '/coc',
  },
  {
    name: 'Terms of Service',
    label: 'Terms of Service',
    url: '/tos',
  },
  {
    name: 'Changelog',
    label: 'Changelog',
    url: '/changelog',
  },
  {
    name: 'Sponsor',
    label: 'Sponsor us on GitHub',
    url: '/github',
  },
];

const Footer = () => {
  return (
    <Box
      textAlign="center"
      position="fixed"
      bottom="0"
      width="100%"
      borderTop="1px solid black"
      backgroundColor="white"
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        {footerLinks.map((item, index) => (
          <Link
            key={index}
            href={item.url}
            margin="10px"
            style={{ color: 'black', textDecoration: 'none' }}
          >
            {item.label}
          </Link>
        ))}
      </Box>
      <Box textAlign="center" margin="20px">
        <Button
          style={{
            border: '0.5px black solid',
            padding: '10px',
            color: 'black',
          }}
          endIcon={<FavoriteIcon style={{ color: 'lightblue' }} />}
        >
          Sponsor Us
        </Button>
      </Box>

    </Box>
  );
};

export default Footer;
