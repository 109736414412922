import React from 'react';
import UserHomeModals from 'components/contentModals/UserDashboard/UserHomeModals';
import {Box,Text} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from '@mui/material';
import UserSideWidgetTwo from 'scenes/widgets/UserWidgets/UserSideWidgetTwo';
import DiscoverFeed from '../Discover';
// Components
import AdvertWidget from 'scenes/widgets/AdvertWidget';
import PublicFeed from '../PublicFeed';



// Feeds
const Homefeed = () => {
    const { _id, picturePath } = useSelector((state) => state.user);
    const user = useSelector((state) => state.user);
    const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

  return (
<>
<Box display="flex" flexDirection="column">
      <Box
        width="100%"
        padding="2rem 6%"
        display={isNonMobileScreens ? 'flex' : 'block'}
        gap="0.5rem"
        justifyContent="space-between"
      >
        {isNonMobileScreens && (
          <Box flexBasis="26%">
            <UserSideWidgetTwo userId={user._id} picturePath={picturePath} />
            {isNonMobileScreens && (
              <Box flexBasis="26%">
                <AdvertWidget />
              </Box>
            )}
          </Box>
        )}
        
        <Box maxWidth="1000px" minWidth="65%">
    
<PublicFeed/>
{/* <DiscoverFeed/> */}

        </Box>
      </Box>

    </Box>



</>
  )
}

export default Homefeed
