import React from "react";
import { Flex } from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";

// ICONS
import { IoPersonAddOutline as FollowIcon } from "react-icons/io5";
import { LuPaintbrush2 as Paintbrush } from "react-icons/lu";
import { GrContact as Contact } from "react-icons/gr";

const UserProfileButtons = ({ user }) => {
  const Links = [
    {
      label: "Follow",
      src: "#",
      icon: <FollowIcon />,
    },
    {
      label: "View Work",
      src: "#",
      icon: <Paintbrush />,
    },
    {
      label: "Contact",
      src: "#",
      icon: <Contact />,
    },
  ];

  return (
    <>
      <Flex margin="10px">
        {Links.map((item, index) => (
          <Flex
            key={index}
            direction="row-reverse"
            margin="10px"
            padding="10px"
            align="center"
          >
            <Button variant="primary" startIcon={item.icon}>
              {item.label}
            </Button>
          </Flex>
        ))}
      </Flex>
      <Button
        bg="black"
        color="white"
        borderRadius="10px"
        px="20px"
        py="10px"
        _hover={{ bg: "gray.800" }}
        _active={{ bg: "gray.900" }}
      >
        Book Appointment
      </Button>
    </>
  );
};

export default UserProfileButtons;
