import {
  ChatBubbleOutlineOutlined,
  FavoriteBorderOutlined,
  FavoriteOutlined,
  ShareOutlined,
  Report,
  Save,
  Favorite,
} from "@mui/icons-material";

import CloseIcon from "@mui/icons-material/Close";

// Todo
// // low level gradient fix on hover
// // rm post details on hover
// * update icons for comments/likes
// * fix animation for comment open
//  * dynamic post page reroute on click
// ?? Move showbox animation to comments section

import CommentForm from "components/Inputs/CommentForm";
import { Box, Divider, IconButton, Typography, useTheme } from "@mui/material";
import { Text } from "@chakra-ui/react";
import FlexBetween from "components/FlexBetween";
import Friend from "components/UserComponents/Friend";
import WidgetWrapper from "components/WidgetWrapper";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPost } from "state";
import UserImage from "components/UserComponents/UserImage";
import UserProfilePicture from "components/UserComponents/UserProfilePicture";
import CommentList from "components/postComponents/CommentList";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import Timestamp from "components/Timestamp";

import gsap from "gsap";
import { Link } from "react-router-dom";

const PostWidget = ({
  postId,
  postUserId,
  name,
  username,
  description,
  location,
  picturePath,
  userPicturePath,
  likes,
  comments,
  createdAt,
  title,
  shop,
}) => {
  const [isComments, setIsComments] = useState(false);
  const [isBoxVisible, setIsBoxVisible] = useState(false);
  const [isCommentsVisible, setIsCommentsVisible] = useState(false);
  const [detailsVisible, setDetailsVisible] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const loggedInUserId = useSelector((state) => state.user._id);
  const isLiked = Boolean(likes[loggedInUserId]);
  const likeCount = Object.keys(likes).length;
  const [anchorEl, setAnchorEl] = useState(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const { palette } = useTheme();
  const main = palette.neutral.main;

  // ?? Helpers
  const animateLikeButton = () => {
    gsap.to(`#like-button-${postId}`, {
      scale: 8.2,
      duration: 0.4,
      yoyo: true,
      repeat: 1,
    });
  };

  const showBox = () => {
    setIsBoxVisible(true);
    gsap.to(`#bottom-box-${postId}`, {
      height: "0%",
      duration: 0.5,
      ease: "power2.out",
    });
  };

  const hideBox = () => {
    gsap.to(`#bottom-box-${postId}`, {
      height: "100%",
      duration: 0.5,
      ease: "power2.in",
      onComplete: () => setIsBoxVisible(false),
    });
  };

  const toggleCommentsVisibility = () => {
    setIsCommentsVisible(!isCommentsVisible);
    setIsComments(!isComments);
  };

  const handleReply = async (commentId, replyText) => {
    try {
      const response = await fetch(
        `http://localhost:8000/posts/${postId}/comments/${commentId}/replies`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            userId: loggedInUserId,
            comment: replyText,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to create reply. Status: ${response.status}`);
      }

      const updatedPost = await response.json();
    } catch (error) {
      console.error("Error creating reply:", error);
    }
  };

  const addComment = async (newComment) => {
    try {
      const response = await fetch(
        `http://localhost:8000/posts/${postId}/comments`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId: loggedInUserId, comment: newComment }),
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to add comment. Status: ${response.status}`);
      }

      const updatedPost = await response.json();
      dispatch(setPost({ post: updatedPost }));
    } catch (error) {
      console.error("Error adding comment:", error);
      // Handle the error as needed (e.g., display an error message to the user)
    }
  };

  const patchLike = async () => {
    const response = await fetch(`http://localhost:8000/posts/${postId}/like`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId: loggedInUserId }),
    });
    const updatedPost = await response.json();
    dispatch(setPost({ post: updatedPost }));
    animateLikeButton();
  };

  const handlePopoverOpen = (e) => {
    setAnchorEl(e.currentTarget);
    setIsPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setIsPopoverOpen(false);
  };

  const handleCommentsIconClick = () => {
    setIsCommentsVisible(!isCommentsVisible);
    setIsComments(!isComments);

    if (!isCommentsVisible) {
      hideBox();
    } else {
      showBox();
    }
  };

  const handlePostClick = () => {
    window.location.href = `/post/${postId}`;
  };



















  return (
    <WidgetWrapper m="2rem 0" display="flex">
      <Box
        onClick={handlePostClick}
        style={{
          cursor: "pointer",
          position: "relative",
          borderRadius: "30px",
          maxWidth: "900px",
          minWidth: "400px",  
          overflow: "hidden",
          height: isCommentsVisible ? "600px" : "500px",
          minHeight:'500px',
          boxShadow: isHovered ? "none" : "10px 10px 10px rgba(0,0,0,0.3)",
          transform: isHovered ? "translateY(-5px)" : "none",
          transition: "transform  0.3s ease-in-out",
          backgroundColor: "white",

        }}
        onMouseEnter={() => {
          setIsHovered(true);
          showBox();
        }}
        onMouseLeave={() => {
          setIsHovered(false);
          hideBox();
        }}
      >
        <div
          id={`bottom-box-${postId}`}
          style={{
            position: "absolute",
            cursor: "pointer",
            bottom: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background:
              "linear-gradient(to bottom, transparent, rgba(0,0,0,0.8))",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            textAlign: "center",
            transition: "opacity 0.3s ease-in-out",
            opacity: isHovered ? 0 : 1,
          }}
        ></div>


{/* BG IMAGE */}
        <div
          style={{
            backgroundImage: picturePath ? `url(${picturePath})` : "none",
            backgroundPosition: "center",
            backgroundSize: "cover",
            height: "100%",
            zIndex: 0,
          }}
        ></div>

        {/* POST DETAILS */}
        <Box
          position="absolute"
          bottom={0}
          left={0}
          right={0}
          p="20px"
          color="darkgray"
          textAlign="left"
          transition="opacity 0.3s ease-in-out"
          // opacity= {isHovered ? 1 : 0}
          // zIndex={2}
        >
          {/* POST DETAILS */}
          <div
            style={{
              cursor: "pointer",
              width: "100%",
              height: "100%",
              background:
                "linear-gradient(to bottom, transparent, rgba(0,0,0,0.8))",
              display: isCommentsVisible ? "none" : "flex",
              flexDirection: "column",
              color: "#FBF9F1",
              WebkitBackgroundClip: "text",
              backgroundClip: "text",
              textAlign: "left",
              transition: "opacity 0.3s ease-in-out",
              opacity: isHovered && detailsVisible ? 0 : 100,
              transition: "opacity  0.2s ease-in-out",
            }}
          >
            <Box display="flex" alignItems="center" color="#FBF9F1">




              <UserProfilePicture
                userPicturePath={userPicturePath}
                alt={name}
                size="33px"
              />
              
              
              <Typography
                fontSize="19.3px"
                fontWeight="bold"
                fontFamily="Darker Grotesque Variable"
              >
                {name}
              </Typography>
            </Box>

            <Box display="flex" flexDirection="column">
              <Typography variant="h6">{title}</Typography>
              <Typography variant="p">{description}</Typography>
              <Timestamp createdAt={createdAt} />
            </Box>
          </div>

          {/* POST STATS */}
          <Box display="flex" alignItems="center">
            <IconButton onClick={patchLike} id={`like-button-${postId}`}>
              {isLiked ? (
                <Favorite style={{ color: "red" }} />
              ) : (
                <FavoriteBorderOutlined style={{ color: "red" }} />
              )}
            </IconButton>

            <Typography>{likeCount}</Typography>
            <IconButton
              onClick={handleCommentsIconClick}
              style={{ marginLeft: "20px" }}
            >
              <ChatBubbleOutlineOutlined style={{ color: "gray" }} />
            </IconButton>
            <Typography>{comments.length}</Typography>
          </Box>

          <Box
            style={{
              position: "absolute",
              // opacity: isCommentsVisible ? 100 : 0 ,
              bottom: 0,
              left: 0,
              right: 0,
              color: "darkgray",
              textAlign: "left",
              transition: "opacity 0.3s ease-in-out",
            }}
          >
            {isComments && (
              <Box
                mt="0.5rem"
                sx={{
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  padding: "1rem",
                }}
              >
                <IconButton
                  onClick={toggleCommentsVisibility}
                  style={{
                    color: "white",
                    position: "relative",
                    top: ["15px"],
                  }}
                >
                  {isCommentsVisible ? <CloseIcon /> : null}
                </IconButton>
                <CommentList
                  comments={comments}
                  userPicturePath={userPicturePath}
                  onReply={handleReply}
                />
                <CommentForm onSubmit={addComment} />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </WidgetWrapper>
  );
};

export default PostWidget;
