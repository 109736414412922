import React from 'react';
import {Box, Text} from '@chakra-ui/react';

const Logo = ({size,color}) => {
  return (
<div>
    <Text style={{
        fontFamily: 'Back To Black Demo', fontSize:['25px']
    }}  >Inkly</Text>
</div>
  )
}

export default Logo
