import React from 'react';
import { Box, Typography, Divider, Link } from '@mui/material';
import { Text } from '@chakra-ui/react';
import UserImage from './UserImage';
import Timestamp from '../Timestamp';

const UserProfilePicture = ({ userPicturePath, picturePath, size, activeStatus = true, name, location, createdAt, shop, username, userId }) => {
  return (
    <Box display="flex" alignItems="center" position="relative">
      {/* User image */}
      <Box position="relative" zIndex="1">
        <UserImage picturePath={userPicturePath} size={size} activeStatus={activeStatus} />
      </Box>
      {/* User information */}
      <Box marginLeft="20px">
        {createdAt && <Timestamp createdAt={createdAt} />}
        <Box display="flex" alignItems="center">
          <Typography variant="h6" color='white'> {name}</Typography>
          <Link href={`profile/${userId}`} underline='hover' color='gray'>
            <Typography variant="h6" fontSize='9px' color='gray'> {username}</Typography>
          </Link>
        </Box>
        <Typography variant='p' fontSize='9px' color='gray'>{location}</Typography>
      </Box>
    </Box>
  );
};

export default UserProfilePicture;
