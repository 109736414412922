import { createSlice } from "@reduxjs/toolkit";


// ACTIONS FILE

const initialState = {
  mode: "light",
  user: null,
  artist: null,
  token: null,
  shop: null,
  posts: [],
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
    },
    setLogin: (state, action) => {
      const {user,artist,token} = action.payload || {};
      state.user = action.payload.user;
      state.artist = action.payload.artist;
      state.token = action.payload.token;
    },
    setLogout: (state) => {
      state.user = null;
      state.artist = null;
      state.token = null;
      state.shop = null
    },
    setFriends: (state, action) => {
      if (state.user) {
        state.user.friends = action.payload.friends;
      } else {
        console.error("user friends non-existent :(");
      }
    },
    setPosts: (state, action) => {
      state.posts = action.payload.posts;
    },
    setPost: (state, action) => {
      const updatedPosts = state.posts.map((post) => {
        if (post._id === action.payload.post._id) return action.payload.post;
        return post;
      });
      state.posts = updatedPosts;
    },
    userUpdateRequest: (state) => {
      state.loading = true;
    },
    userUpdateSuccess: (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.success = true;
    },
    userUpdateFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },


// ** SHOP REDUCERS
setShop: (state,action) => {
  state.shop = action.payload.shop;
}


  },
});

export const {
  setMode,
  setLogin,
  setLogout,
  setFriends,
  setPosts,
  setPost,
  userUpdateRequest,
  userUpdateSuccess,
  userUpdateFail,
  setShop,
} = authSlice.actions;

export const userUpdateReducer = authSlice.reducer;

export default authSlice.reducer;
