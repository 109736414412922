import React, { useState } from 'react';
import { Box, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import MyPostWidget from 'scenes/widgets/CreatePost';
import CreatePostWidget from './postComponents/CreatePostWidget';

const ModalWrapper = styled(Box)({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  padding: '20px',
  zIndex: '999',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
});

const Overlay = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.7)', 
  zIndex: '998', 
});

export default function LabelBottomNavigation() {
  const [value, setValue] = useState('home');
  const [showCreatePostModal, setShowCreatePostModal] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCreatePostClick = () => {
    setShowCreatePostModal(true);
  };

  const handleClosePostModal = () => {
    setShowCreatePostModal(false);
  };

  const HomeIcon = <box-icon color="white" name="home-alt"></box-icon>;
  const HeartIcon = <box-icon name="heart" color="white"></box-icon>;
  const Plus = <box-icon color="white" name="plus-medical"></box-icon>;
  const Bell = <box-icon name="bell" color="white"></box-icon>;
  const UserPin = <box-icon name="user" color="white"></box-icon>;

  return (
    <>
      <Box
        position="sticky"
        bottom="0"
        borderRadius="20px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        backgroundColor="#0E0E12"
        width="100%"
      >
        <BottomNavigation
          sx={{
            width: '100%',
            background: 'inherit',
            height: '90px',
            color: 'white',
          }}
          value={value}
          onChange={handleChange}
        >
          <BottomNavigationAction
            value="home"
            icon={HomeIcon}
            component={Link}
            to="/home"
          />
          <BottomNavigationAction
            icon={HeartIcon}
            value="favorites"
            component={Link}
            to="/profile"
          />
          <BottomNavigationAction
            label="New post"
            value="new_post"
            style={{
              color: 'lightblue',
              fontSize: '90px',
              color: 'orange',
              borderRadius: '50%',
            }}
            icon={Plus}
            component="button"
            onClick={handleCreatePostClick}
          />
          <BottomNavigationAction
            style={{
              color: 'lightblue',
              fontSize: '90px',
              color: 'orange',
              borderRadius: '50%',
            }}
            icon={Bell}
            component={Link}
          />
          <BottomNavigationAction
            icon={UserPin}
            value="profile"
            component={Link}
            to="/profile"
          />
        </BottomNavigation>
        
      </Box>
      {showCreatePostModal && (
        <>
          <Overlay onClick={handleClosePostModal} />
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            padding='50px'
          >
            <ModalWrapper>
              <CreatePostWidget onClose={handleClosePostModal} setShowCreatePostModal={setShowCreatePostModal}/>
            </ModalWrapper>
          </Box>
        </>
      )}
    </>
  );
}
